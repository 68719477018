import Carousel from "react-bootstrap/Carousel";
import "../scss/homepage.scss";
import "../scss/common.scss";
import "../scss/stackAnimation.scss";
// import "../scss/whatweBringCard.scss";
import Card1 from "../assets/homepage/1.svg";
import Card2 from "../assets/homepage/2.svg";
import CardMob1 from "../assets/homepage/Mob_1.svg";
import CardMob2 from "../assets/homepage/Mob_2.svg";
import CaseCardImg from "../assets/homepage/caseImage.png";
import OneImg from "../assets/homepage/One.svg";
import TwoImg from "../assets/homepage/Two.svg";
import ThreeImg from "../assets/homepage/Three.svg";
import { TestimonialCarouselComp } from "../components/CarouselComp";
import { IneractiveBtn } from "./BtnInteractive";
import CapabilitiesCard from "./CapabilitiesCard";
import WhatwebringImg from "../assets/homepage/whatwebringimghome.webp";
import WhatwebringFill from "../assets/homepage/whatwebringfill.svg";
import SingleInsight from "./SingleInsight";

import { useNavigate, useLocation } from "react-router-dom";
import { NAVIGATION_ROUTES, NAV_SUB_ROUTES } from "../constants/routes";

import { useEffect } from "react";

export const BannerComponent = ({ bannerList }) => {
  const navigate = useNavigate();

  useEffect(() => {});
  return (
    <div className="heroContainerParent">
      <div className="heroContainer">
        <Carousel
          controls={true}
          id="homeBanner"
          data-interval="false"
          style={{ display: "flex", width: "100%" }}
        >
          {bannerList.map((banner) => (
            <Carousel.Item className="d.block w-100">
              <div>
                <video
                  src={banner.url}
                  className="heroContent heroRes showDesk"
                  autoPlay
                  muted
                  loop
                  playsInline
                ></video>
                <video
                  src={banner.urlMobile}
                  className="heroContent heroRes showMob"
                  autoPlay
                  muted
                  loop
                  playsInline
                ></video>
              </div>

              <div className="btnLocation">
                <div className="heroTextContainer">{banner.title}</div>
                {banner.cta_info === "Initiate Now" ? (
                  <div onClick={() => navigate(NAV_SUB_ROUTES.SOLUTION_DETAIL)}>
                    <IneractiveBtn title={banner.cta_info} />
                  </div>
                ) : (
                  <div onClick={() => navigate(NAVIGATION_ROUTES.SOLUTIONS)}>
                    <IneractiveBtn title={banner.cta_info} />
                  </div>
                )}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export const CaseStudy = ({ caseStudy }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  function handleCaseStudylanding(id) {
    queryParams.set("id", id);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAVIGATION_ROUTES.CASESTUDY, search: newSearch });
    window.scrollTo(0, 0);
  }
  if (caseStudy !== null) {
    return (
      <div className="caseStudyContainer">
        <div className="caseStudyCardDesk">
          <div className="casemainText">Case study</div>
          <div className="casemainSubText">
            Our success stories demonstrate the value we contribute to
            businesses
          </div>

          <img className="logo_card" src={Card2} alt="cardimg" />
          <img className="cs_details" src={Card1} alt="cardimg" />
          <div className="caseCardImgDeskContainer">
            <img
              className="caseCardImgDesk"
              src={caseStudy.img_url}
              alt="cardimg"
            />
          </div>
          <div className="mainCaseTextbox">
            <div>{caseStudy.title}</div>
            <div className="subMainText">
              <p>{caseStudy.description}</p>
            </div>
          </div>
          <img
            className="stickerCardImgDesk"
            src={caseStudy.logo_url}
            alt="cardimg"
          />
          <img className="oneImgDesk" src={OneImg} alt="one" />
          <img className="twoImgDesk" src={TwoImg} alt="two" />
          <img className="threeImgDesk" src={ThreeImg} alt="three" />
          <div className="caseTextBottom">
            <div className="underText">{caseStudy.impact.first}</div>
            <div className="underText">{caseStudy.impact.second}</div>
            <div className="underTextLast">{caseStudy.impact.third}</div>
          </div>
          <div
            onClick={() => handleCaseStudylanding(caseStudy.id)}
            className="caseStudyDeskBtn"
          >
            <IneractiveBtn title={"Learn More"} />
          </div>
        </div>
        <div className="caseStudyTextMob">Case study</div>
        <div className="ourSuccessText">
          Our success stories demonstrate the value we contribute to businesses
        </div>
        <div className="caseStudyCardMob">
          <img className="cardMobPeice1" src={CardMob2} alt="imgSucsess" />
          <img className="cardMobPeice2" src={CardMob1} alt="imgSuccess" />
          <div className="cardElemMob">
            <div>
              <img
                className="caseCardImgMob"
                src={caseStudy.img_url}
                alt="cardImg"
              />
            </div>
            <div className="cardMaintextMob">{caseStudy.title}</div>
            <div className="containerImgCaseCardMob">
              <div>
                <img className="" src={OneImg} alt="oneIcon" />
              </div>
              <div>{caseStudy.impact.first}</div>
              <div>
                <img className="" src={TwoImg} alt="twoIcon" />
              </div>
              <div>{caseStudy.impact.second}</div>
              <div>
                <img className="" src={ThreeImg} alt="threeIcon" />
              </div>
              <div>{caseStudy.impact.third}</div>
            </div>
            <div onClick={() => handleCaseStudylanding(caseStudy.id)}>
              <IneractiveBtn title={"Learn More"} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export const Testimonials = ({ testimonials, title }) => {
  if (testimonials.length === 0) return <></>;
  else
    return (
      <div className="testimonialContainer">
        <div className="clientText">Client testimonials</div>
        <div className="impactText">{title}</div>
        <TestimonialCarouselComp testimonials={testimonials} />
      </div>
    );
};

export const Capabilities = ({ topSolutions }) => {
  const navigate = useNavigate();
  function handleKnowMoreBtn() {
    navigate(NAVIGATION_ROUTES.SOLUTIONS);
    window.scrollTo(0, 0);
  }
  return (
    <div className="capabilitiesContainer">
      <div className="capabilitiesTxt">Our capabilities</div>
      <div className="designedTxt">
        Designed to tackle industry-specific challenges, such as digital fraud
        detection, data integration, and sales pipeline dashboards.
      </div>
      <div className="capabilitiesCardContainer">
        {topSolutions.map((solution, index) => (
          <CapabilitiesCard key={index} id={index} solution={solution} />
        ))}
      </div>
      <div className="discoverBtn">
        <IneractiveBtn title={"Discover"} handleRoute={handleKnowMoreBtn} />
      </div>
    </div>
  );
};

export const MerticsBanner = ({ achivementsList }) => {
  return (
    <div className="homePageBanner">
      <div className="dataBannerContainer">
        <div className="traingleBox">
          <span className="bannerHeading">Data</span>
          <br />
          <span className="bannerSubHeading">analysis</span>
        </div>
        <div className="traingleBox">
          <span className="bannerHeading">Actionable</span>
          <br />
          <span className="bannerSubHeading">insights</span>
        </div>
        <div className="traingleBoxNoarrow">
          <span className="bannerHeading">Business</span>
          <br />
          <span className="bannerSubHeading">growth</span>
        </div>
      </div>
      <div className="staticsBanner">
        {achivementsList.map((achivement) => (
          <div className="staticsContent">
            <div className="staticsHeading">{achivement.title}</div>
            <div className="staticsDetails">{achivement.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const WhatWeBring = ({ wwbringCard }) => {
  const navigate = useNavigate();

  function DiveInBtnHandler() {
    navigate(NAVIGATION_ROUTES.COMPANY);
    window.scrollTo(0, 0);
  }

  return (
    <div className="whatwebringContainer">
      <img className="whatwebringFill" src={WhatwebringFill} alt="fillImg" />
      <div className="imgWhatwebring">
        <img className="whatwebringImg" src={WhatwebringImg} alt="fillImg" />
      </div>
      <div className="whatwebringScrollSection">
        <img className="whatwebringImgMob" src={WhatwebringImg} alt="fillImg" />

        <ul id="cards">
          <div className="stickyText">
            <div className="whatwebringHeading">What we bring</div>
            <div className="whatwebringSubHeading">
              Decades of shared expertise Hundreds of collaborations Diverse
              teams
            </div>
          </div>
          {wwbringCard.map((e, index) => {
            return (
              <li
                className="whatweBringCard"
                style={{
                  backgroundColor: `${e.title.attributes.Background_color}`,
                }}
                id={`card_${index + 1}`}
              >
                <div className="card__content">
                  <div className="whatweBringCardTitle">
                    {e.title.attributes.Title}
                  </div>
                  <div className="whatweBringCardText">
                    {e.title.attributes.Description}
                  </div>
                  <div>
                    <img className="whatweBringCardImg" src={e.url[0]} alt="" />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="diveinBtn">
          <IneractiveBtn title={"Dive In"} handleRoute={DiveInBtnHandler} />
        </div>
      </div>
    </div>
  );
};
