import React, { useEffect, useState, useRef } from "react";
import { getPeople } from "../api/people";
import Header from "../components/Header";
import "../scss/companypage.scss";
import "../scss/homepage.scss";
import Playbtn from "../assets/playBtn.svg";
import StoryImage from "../assets/CompanyPage/storyImage.svg";
import { Footer } from "../components/Footer";
import JourneyCard from "../components/JourneyCard";
import WhyNgenuxVideoBanner from "../assets/career/whyNgenux.webp";
import { Helmet } from "react-helmet";

import {
  CompanyValues,
  OurStory,
  OurTeam,
  CompanyCSR,
  FounderWord,
} from "../components/CompanyValuesComponent";
import { peopleSeralizer } from "../utils/serializer";

export const CompanyPage = () => {
  const [playState, setPlayState] = useState(false);
  const videoRef = useRef(null);
  let video = document.getElementById("myVideo");
  const [peopleInfo, setPeopleInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      let video = document.getElementById("myVideo");
      videoRef.current = video;
    }, 1);
  }, []);

  const getPeopleInfo = () => {
    getPeople().then((response) => {
      setPeopleInfo([...peopleInfo, ...peopleSeralizer(response)]);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getPeopleInfo();
    }, 1);
  }, []);
  const playVideo = () => {
    if (!playState) {
      videoRef.current.play();

      setPlayState(true);
    } else {
      videoRef.currentvideo.stop();
      setPlayState(true);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Explore About Ngenux Solutions | AI Development Company</title>
        <meta
          name="description"
          content="Explore the story, values, and team information of Ngenux, a trusted AI development company that can help you craft scalable data solutions for the future."
        />
      </Helmet>
      <Header />
      <div className="titleParentContianer">
        <div className="bGSecion"></div>
        <div className="titleContent">
          <div className="titleContentLeft">
            <div>At Ngenux,{window.innerWidth <= 1366 && `we bring`}</div>
            <div className="verticalFlipContainer">
              {window.innerWidth > 1366 && <div>we bring</div>}
              <div className="verticalflip">
                <span>Quality</span>
                <span>Teamwork</span>
                <span>Collaboration</span>
              </div>
            </div>
          </div>
          <div className="titleContenRight">
            <span>People are at the core of&nbsp;</span>
            <span>what Ngenux does. That is</span>
            <span>&nbsp;how we build trust.</span>
          </div>
        </div>
      </div>

      <div className="videoWrapper">
        <img className="videoCompany" src={WhyNgenuxVideoBanner} alt=""/>

        <div className="playButtonContainer">
          <img src={Playbtn} alt=""/>
        </div>
      </div>

      {/* <div className="videoWrapper">
        <video
        className="videoCompany"
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
          id="myVideo"
          // autoPlay
          muted
          loop
        ></video>
      </div> */}
      <CompanyValues />
      <OurStory />
      {!isLoading && <OurTeam team={peopleInfo} />}
      <FounderWord />
      <CompanyCSR />
      <JourneyCard />
      <Footer />
    </div>
  );
};
