import React, { useState } from "react";
import ArrowNav from "../assets/leftArrow.svg";

import "../scss/galleryCsr.scss";
export const GalleryCsr = ({media}) => {

  const [index, setIndex] = useState(0);

  const FilterArr = media.csr_media.map((ind)=>{return ind})
  const [next, setNext] = useState(FilterArr[index]);
  const nextIndex = (index + 1) % FilterArr.length; // Calculate the next index
  const prevIndex = (index - 1 + FilterArr.length) % FilterArr.length; // Calculate the previous index
  const handleClickNext = () => {
    setIndex(nextIndex); // Update the index to the next one
    setNext(FilterArr[nextIndex]);
  };

  const handleClickPrevious = () => {
    setIndex(prevIndex); // Update the index to the previous one
    setNext(FilterArr[prevIndex]);
  };
  return (
<div className="galleryContainer">
      <div>
        <div className="galleryImgContainer">
        <img
          className="galleryImg"
          src={process.env.REACT_APP_BASE_LINK + next.url}
          alt=""
        />
        </div>
        </div>
        <div>
          <div className="arrowContainerCsr">
            <img onClick={handleClickNext} className="rotateArrow pointerMouse"src={ArrowNav} alt="navigate" />
            <img onClick={handleClickPrevious} className="pointerMouse" src={ArrowNav} alt="" />

          </div>
        </div>
      </div>
   
  );
};
