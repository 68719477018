import axios from "axios";

function apiRequestError(axiosRes, error) {
  if (axiosRes.isAxiosError(error)) {
    // Handle Axios-specific errors
    if (error.response) {
      console.log(`Request failed with status code: ${error.response.status}`);
      console.log("Response data:", error.response.data);
    } else if (error.request) {
      console.log("Request made but no response received. Is the server down?");
    } else {
      console.log("Error during request setup:", error.message);
    }
  } else {
    // Handle other general errors
    console.error("An error occurred:", error.message);
  }
}

export const getInsights = async () => {
  const url = process.env.REACT_APP_BASE_LINK + "/api/insights?populate=*";

  try {
    const response = await axios.get(url);

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(
        `Failed to fetch insights. Status code: ${response.status}`
      );
    }
  } catch (error) {
    apiRequestError(axios, error);
  }
};
