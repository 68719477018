import React from "react"
import "../scss/impactCard.scss"

export const ImpactCard = ({impact}) => {
    return (

        <div className="impactContainer">
            <img src={impact.img} alt="" />
            <span className="impactContainerTitle">{impact.title}</span>
            <div className="impactContaierDes">{impact.decription}</div>
        </div>
    )
}