import React from "react";
import ArrowIcon from "../assets/arrowIconBtn.svg";
import "../scss/interactiveBtn.scss";

export const IneractiveBtn = ({ title, btnType, handleRoute }) => {
  return (
    <div className={`interactiveBtn ${btnType}`} onClick={handleRoute}>
      <span>{title}</span>
      <img className="arrowInteractiveTransform" src={ArrowIcon} alt="" />
    </div>
  );
};
