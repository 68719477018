import React, { lazy, Suspense, useState } from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer";
import { Contact } from "../components/ContactUsComponents";
import { ContactForm } from "../components/ContactUsComponents";
import "../scss/header.scss";
import "../scss/contactUs.scss";
import "../scss/common.scss";

const Header = lazy(() => import("../components/Header"));

const ContactUs = () => {

  let schemaContactUs = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    "name": "Ngenux Solutions Private Limited",
    "image": "https://www.ngenux.com/logo.svg",
    "@id": "",
    "url": "https://www.ngenux.com/",
    "telephone": "9606723030",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "334/28 14th Cross Road 2nd Block, Jaya Nagar 1st Block, Jayanagar",
      "addressLocality": "Bengaluru",
      "postalCode": "560011",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 12.937400,
      "longitude": 77.582100
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "10:00",
      "closes": "18:00"
    },
    "sameAs": "https://www.linkedin.com/company/ngenux-solutions-pvt-ltd/" 
  }
  
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
    <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaContactUs)}
        </script>

        <title>Contact Ngenux - AI & Data Experts in Bengaluru</title>
        <meta
          name="description"
          content="Get in touch with Ngenux, Bengaluru's premier AI and Data-Driven Company. Explore innovative solutions and expert insights. Contact us today!"
        />
      </Helmet>
    <Suspense fallback={"Loading..."}>
      <Header />
      <Contact handleshow={() => setShowPopup(true)} />
      {showPopup ? (
        <ContactForm handlehide={() => setShowPopup(false)} />
      ) : null}
      <Footer />
    </Suspense>
    </>
  );
};

export default ContactUs;
