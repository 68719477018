import React from "react";
import "../scss/privacy.scss";
import Header from "../components/Header";
import { Footer } from "../components/Footer";

const Privacy = () => {
  return (
    <>
    <Header/>
    <div className="privacyContainer">
      <div className="privacyHeading">COOKIES</div>
      <div className="privacyContent">
        What exactly are cookies? In order to collect the information including
        personal data as described in this Notice, we may use cookies and
        similar technology on our website. A cookie is a small piece of
        information which is sent to your browser and stored on your computer’s
        hard drive, mobile phone or other device. Cookies can be first party,
        i.e. cookies that the website you are visiting places on your device, or
        third party cookies, i.e. cookies placed on your device through the
        website but by third parties, such as, Google.Cookies are used for the
        following purposes:<br/><br/>
        <ul>
          <li>
            <strong>Necessary cookies -</strong> These cookies are required for you to be able to
            use some important features on our website, such as logging in.
            These cookies don’t collect any Personal Data.{" "}
          </li>
          <li>
            <strong>Functionality cookies -</strong> These cookies provide functionality that makes
            using our service more convenient and makes providing more
            personalized features possible. For example, they might remember
            your name and e-mail so you don’t have to re-enter this information
            next time when commenting.
          </li>
          <li>
            <strong>Analytics cookies -</strong> These cookies are used to track the use and
            performance of our website and services.
          </li>
          <li>
            <strong>Advertising cookies -</strong> These cookies are used to deliver advertisements
            that are relevant to you and to your interests. In addition, they
            are used to limit the number of times you see an advertisement. They
            are usually placed to the website by advertising networks with the
            website operator’s permission. These cookies remember that you have
            visited a website and this information is shared with other
            organizations such as advertisers. Often targeting or advertising
            cookies will be linked to site functionality provided by the other
            organization.
          </li>
        </ul>
        Analytics Cookies allow Ngenux to monitor and analyze the usage of the
        Website. Our cookies store randomly assigned user identification
        numbers, pages visited by a visitor, time spent on a page, clicks,
        demographic information about the visitor such as country, IP address,
        device and operating system used by the visitor. We also use cookies for
        distinguishing users and user sessions and for identifying new users and
        sessions, to determine the traffic source that explains how the user
        reached our site and to throttle user request rate in order to limit the
        number of data requests in a time frame. Some of the cookies used by us
        are necessary for the functionality of the website to store the consent
        of the users for dropping advertising and other cookies. We also use
        advertising cookies of our partners like Facebook, LinkedIn and Twitter
        for re-targeting and marketing purposes. When you arrive on our website
        a pop-up message will appear asking for your consent to place cookies on
        your device. In order to provide your consent, please go through the
        details of cookies used by our website for different purposes. You can
        set your preferences accordingly and click ‘OK. Once your consent has
        been provided, this message will not appear again when you revisit. If
        you, or another user of your device, wish to withdraw your consent at
        any time, you can do so by opting out as per your choice by
        clicking here.
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Privacy;
