import "../scss/customersPage.scss";
import CokeImg from "../assets/customersPage/cokeCardImg.png";
import ArrowIcon from "../assets/homepage/blueArrow.svg";
import { useState } from "react";
import CaseStudiesCustomersCard from "./CaseStudyCustomer";
import VideoBanner from "../assets/customersPage/videoBanner.mp4";
import CokeLogo from "../assets/customersPage/image 709.svg";
import MinifyLogo from "../assets/customersPage/image 715.svg";
import ParoLogo from "../assets/customersPage/Paro_logo.png";
import Blend360Logo from "../assets/customersPage/Group 39655.svg";
import MotovityLabs from "../assets/customersPage/Motivity_labs_logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import LoadmoreIcon from "../assets/loadmorecircle.svg"

export const CustomersBanner = () => {
  return (
    <div className="customersBannerContainer">
      <div className="customersBannerChildContainer">
        <video
          src={VideoBanner}
          className="customersVideoBanner"
          autoPlay
          muted
          loop
          playsInline
        ></video>
      </div>
      <div className="customersBannerElem">
        <div className="customersBannerHeading">
          Discover the future with{" "}
          <span className="bannerSubHeadingTxt">AI &Data</span>
        </div>

        <div className="customersBannerFooter">
          <div className="customersBannerSubHeading">
            We cross the last mile with the industry leaders in AI & Data
          </div>
          <div className="customersBannerLogos">
            <img src={ParoLogo} alt="img" />
            <img src={CokeLogo} alt="img" />
            <img src={Blend360Logo} alt="img" />
            <img src={MinifyLogo} alt="img" />
            <img src={MotovityLabs} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeaturedCaseStudies = ({ AllcaseStudeis }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  function handleCaseStudyBrandlanding(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAVIGATION_ROUTES.CASESTUDY, search: newSearch });
    window.scrollTo(0, 0);
  }

  const hasNonFeatured = AllcaseStudeis.some((e) => e.isfeatured);

if(hasNonFeatured === false){
  return <></>
}
else {
  
  return (
    <div className="featuredCaseStudiesContainer">
      <div className="featuredCaseStudyTitle">Featured case studies</div>
      <div className="featuredCaseStudyHeading">
        Client success with amplified business impact
      </div>
      <div className="caseStudyFeaturedCardConatiner">
        {/* card starts here */}
        {AllcaseStudeis.filter((e) => e.isfeatured === true).map((e) => {
          return (
            <div
              onClick={() => handleCaseStudyBrandlanding(e.id)}
              key={e.id}
              className="caseStudyFeaturedCard pointerMouse"
            >
              <div className="caseStudyFeaturedImgContainer">
                <img
                  className="caseStudyFeaturedImgCard"
                  src={e.img}
                  alt="cardImg"
                />
                <div className="stickerCardContainerImg">
                  <img className="stickerCardImg" src={e.logo} alt="sticker" />
                </div>
              </div>
              <div className="caseStudyFeaturedCardTitle">
                {e.brandDetails.name}
              </div>
              <div className="caseStudyFeaturedCardDescription">
                {e.brandDetails.brandWork}
              </div>
              <div className="caseStudyFeaturedCardButton">
                case study <img src={ArrowIcon} alt="arrowicon" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
      }
};

export const CaseStudiesCustomers = ({ AllcaseStudeis }) => {
  const [selectedTab, setSelectedTab] = useState("All");
  const [loadMore, setLoadMore] = useState(6);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  function handleTabSelection(tab) {
    setSelectedTab(tab);
  }

  function handleLoadMore() {
    setLoadMore(loadMore + 3);
  }
  function handleCaseStudylanding(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAVIGATION_ROUTES.CASESTUDY, search: newSearch });
    window.scrollTo(0, 0);
  }
  const uniqueTags = [...new Set(AllcaseStudeis.map((e) => e.tag))];


  return (
    <div className="caseStudiesCustomersContainer">
      <div className="featuredCaseStudyTitle">Case studies</div>
      <div className="featuredCaseStudyHeading">
        Your victories, our success
      </div>
      <div className="filtertabCaseStudy">
        <div
          onClick={() => handleTabSelection("All")}
          className={`filterCaseStudyBtn ${
            selectedTab === "All" ? "selected" : ""
          }`}
        >
          All
        </div>
        {uniqueTags.map((e) => {
          return (
            <div
              onClick={() => handleTabSelection(e)}
              className={`filterCaseStudyBtn ${
                selectedTab === e ? "selected" : ""
              }`}
            >
              {e}
            </div>
          );
        })}
      </div>
      <div className="caseStudiesCardContainer">
        {AllcaseStudeis.filter(
          (e) => selectedTab === "All" || e.tag === selectedTab 
        )
          .slice(0, loadMore)
          .map((e) => (
            <div onClick={() => handleCaseStudylanding(e.id)}>
              <CaseStudiesCustomersCard
                key={e.id}
                title={e.tag}
                description={e.brandDetails.brandWork}
                CardImg={e.img}
              />
            </div>
          ))}
      </div>
      {loadMore > AllcaseStudeis.length ? null : (
        <div onClick={handleLoadMore} className="loadMoreBtn">
          Load More <img src={LoadmoreIcon} alt="loadmore"/>
        </div>
      )}
    </div>
  );
};
