import React from "react";
import NgenuxLogo from "../assets/ngenuxlogoDesk.svg";
import HamburgerIcon from "../assets/hamburgerIcon.svg";
import NgenuxMenuLogo from "../assets/NgenuxMenuLogo.svg";
import DownIcon from "../assets/downIcon.svg";
import CloseIconMenu from "../assets/crossMenu.svg";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../scss/header.scss";
import "../scss/common.scss";
import "../scss/contactUsButton.scss";
import { ContactusButton } from "./ContactusButton";
import { NAVIGATION_ROUTES, NAV_SUB_ROUTES } from "../constants/routes";
import { getSolutions } from "../api/solutionsLandingPage";
import { solutionsSerializer } from "../utils/serializer";

const Header = () => {
  const [showmenu, setShowmenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [cardSolutions, setCardSolutions] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundColor:
      scrollPosition > 0 ? "rgba(12, 2, 37, 0.90)" : "transparent",
  };

  const navigate = useNavigate();
  let currentRoute = window.location.pathname;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getPageDetails = () => {
    getSolutions().then((response) => {
      setCardSolutions([...cardSolutions, ...solutionsSerializer(response)]);
      // setIsLoading(false);
    });
  };

  useEffect(() => {
    getPageDetails();
  }, [getSolutions]);

  function solutionshandler(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAV_SUB_ROUTES.SOLUTION_DETAIL, search: newSearch });
    window.location.reload();
  }

  const HeaderTag = ({ headername, route }) => {
    if (currentRoute.startsWith(route) || currentRoute === "/") {
      return <div>{headername}</div>;
    } else {
      return <div className="lightHeaderText">{headername}</div>;
    }
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showmenu) {
      document.body.classList.add("body-no-scroll");
    } else {
      document.body.classList.remove("body-no-scroll");
    }

    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [showmenu]);

  const handleTransformArrow = () => {
    navigate(NAVIGATION_ROUTES.SOLUTIONS);
  };

  const handleMenu = () => {
    setShowmenu(!showmenu);
  };

  const Menu = ({ showmenu }) => {
    const [showSolutionsBox, setSolutionsBox] = useState(false);
    const [arrowTransformed, setArrowTransformed] = useState(false);

    const SoultionL2pages = () => {
      return (
        <div className="solutionContainerClicked res-menuItemSmall">
          {cardSolutions.map((solution, index) => (
            <div
              key={index}
              onClick={() => solutionshandler(solution.id)}
              className="pointerMouse"
            >
              {solution.title}
            </div>
          ))}
        </div>
      );
    };

    const handleSolutionsClick = () => {
      setSolutionsBox(!showSolutionsBox);
      setArrowTransformed(!arrowTransformed);
    };
    return (
      <div className="menuContainer">
        <div className="menuHeader">
          <div onClick={()=>navigate(NAVIGATION_ROUTES.HOMEPAGE)}>
            <img
              className="pointerMouse ngenuxLogoMenu"
              src={NgenuxMenuLogo}
              alt="logoMob"
            />
          </div>
          <div onClick={handleMenu}>
            <img className="hamBurger" src={CloseIconMenu} alt="menu" />
          </div>
        </div>
        <div className="optionsContainer res-menuItem">
          <div  className="solutionBox">
            <div className="solutionNavigate ">
              <div onClick={()=>navigate(NAVIGATION_ROUTES.SOLUTIONS)}>Solutions</div>{" "}
              <img onClick={handleSolutionsClick}
                className={arrowTransformed ? "transformedArrow" : ""}
                src={DownIcon}
                alt="more"
              />
            </div>
            {showSolutionsBox ? <SoultionL2pages /> : null}
          </div>
          <div onClick={() => navigate(NAVIGATION_ROUTES.CUSTOMERS)}>
            Customers
          </div>
          <div onClick={() => navigate(NAVIGATION_ROUTES.COMPANY)}>Company</div>
          <div onClick={() => navigate(NAVIGATION_ROUTES.CAREERS)}>Careers</div>
          <div onClick={() => navigate(NAVIGATION_ROUTES.INSIGHTSLANDING)}>
            Insights
          </div>
          <div onClick={() => navigate(NAVIGATION_ROUTES.CONTACTUS)}>
            <ContactusButton dynamic={"contactusbtnMob"} />
          </div>
        </div>
      </div>
    );
  };

  const Navbar = () => {
    return (
      <div className={`headerContainer ${scrolled ? "scrolled" : ""}`}>
        <div onClick={()=>navigate(NAVIGATION_ROUTES.HOMEPAGE)}>
          <img
            className="ngenuxLogo pointerMouse"
            src={NgenuxLogo}
            alt="logo"
          />
        </div>

        <div>
          <img
            onClick={handleMenu}
            className="pointerMouse hamBurger"
            src={HamburgerIcon}
            alt="icon"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <div className={`mobileViewClass ${showmenu ? "open" : ""}`}>
        <Menu showmenu={showmenu} />
      </div>
      <div className={`headerDeskContainer ${window.location.pathname === '/csr' ? 'scrClass' : ''}`} style={headerStyle}>
        <div className="headerEleContainer">
          <div
          tabIndex="0"
            className="pointerMouse"
            onClick={() => navigate(NAVIGATION_ROUTES.HOMEPAGE)}
          >
            <img className="ngenuxlogoDesk" src={NgenuxLogo} alt="" />
          </div>
          <div tabIndex="1" className="flexSolutionsTab  menuItem ">
            <p className="pointerMouse" onClick={handleTransformArrow}>
              <HeaderTag headername={"Solutions"} route={"/solutions"} />
            </p>
            <img className="" src={DownIcon} alt="" />
            <div className="hoverContainerBox">
              <div className="hoverContainerElemets">
                {cardSolutions.map((solution, index) => (
                  <div
                    tabIndex={index+2}
                    key={index}
                    onClick={() => solutionshandler(solution.id)}
                    className="pointerMouse"
                  >
                    {solution.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
          tabIndex="3"
            onClick={() => navigate(NAVIGATION_ROUTES.CUSTOMERS)}
            className="pointerMouse menuItem"
          >
            <HeaderTag headername={"Customers"} route={"/customers"} />
          </div>
          <div
          tabIndex="4"
            onClick={() => navigate(NAVIGATION_ROUTES.COMPANY)}
            className="pointerMouse menuItem"
          >
            <HeaderTag headername={"Company"} route={"/company"} />
          </div>
          <div
          tabIndex="5"
            onClick={() => navigate(NAVIGATION_ROUTES.CAREERS)}
            className="pointerMouse menuItem"
          >
            <HeaderTag headername={"Careers"} route={"/careers"} />
          </div>
          <div
          tabIndex="6"
            onClick={() => navigate(NAVIGATION_ROUTES.INSIGHTSLANDING)}
            className="pointerMouse menuItem"
          >
            <HeaderTag headername={"Insights"} route={"/insights"} />
          </div>
          <div
          tabIndex="7"
            onClick={() => navigate(NAVIGATION_ROUTES.CONTACTUS)}
            className="pointerMouse"
          >
            <ContactusButton dynamic={"contactusbtnDesk"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
