import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { IneractiveBtn } from "./BtnInteractive";
import { submitContactForm } from "../api/contactus";
import ContactBG from "../assets/ContactUs/contactbg.svg";
import ContactCheckCircle from "../assets/ContactUs/check_circle.svg";
import ContactCheckClose from "../assets/ContactUs/round-close.svg";
import PhoneInput ,{isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const Contact = ({ handleshow }) => {
  return (
    <div className="contactUsContainer">
      <div className="contactUsImg">
        <img src={ContactBG} alt="contact us bg" />
      </div>
      <div className="contactUsContent">
        <div className="contactUsInfoSection">
          <p>Let’s reinvent your business</p>
          <IneractiveBtn title={"Get in touch"} handleRoute={() => handleshow()} />
        </div>
        <div className="contactUsDetailsSection">
          <div className="contactUsMail">
            <p>WRITE TO US</p>
            <p>connect@ngenux.com</p>
          </div>
          <div className="contactUsAddressSection">
            <div className="contactUsaddress">
              <div className="contactUsaddressLocation">
                <p>INDIA</p>
                <div>
                  <span>+91</span>
                  <span className="phdot">.</span>
                  <span>960</span>
                  <span className="phdot">.</span>
                  <span>672</span>
                  <span className="phdot">.</span>
                  <span>3030</span>
                </div>
              </div>
              <div className="contactUsaddressDetails">
                <div>
                  <p>
                    49/25, 3rd Main Road, 2nd Cross Road, Sundernagar,
                    Bangalore 560054
                  </p>
                </div>
                <div className="getDirections">
                <a href="https://maps.app.goo.gl/ZZFKLoSF2T12RsVn8" target="blank">
                  <IneractiveBtn
                    title={"Get Directions"}
                    handleRoute={() => console.log("")}
                  />
                  </a>
                </div>
              </div>
            </div>
            <div className="contactUsaddress">
              <div className="contactUsaddressLocation">
                <p>USA</p>
                <div>
                  <span>713</span>
                  <span className="phdot">.</span>
                  <span>315</span>
                  <span className="phdot">.</span>
                  <span>7373</span>
                </div>
              </div>
              <div className="contactUsaddressDetails">
                <div>
                  <p>
                    Ngenux Solutions Inc. 3130 Grants Lake Blvd #17841 Sugar
                    Land TX 77479
                  </p>
                </div>
                <div className="getDirections">
                  <a href="https://maps.app.goo.gl/cgfgpCSz2QfwPWwGA">
                  <IneractiveBtn
                    title={"Get Directions"}
                    handleRoute={() => console.log("")}
                  />
                   </a>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContactForm = ({ handlehide }) => {
  const [formErrorShow, setFormErrorShow] = useState(false);
  const [showFormInputs, setShowFormInputs] = useState(true);
  const [showReqSuccessPopup, setShowReqSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    read_privacy_statement: false,
    how_do_you_hear: "",
    email: "",
    contact_number: "",
    name: "",
    organization: "",
    job_title: "",
    message: "",
  });

  function updateData(e, str) {
    if(str==="contact_number"){
      setFormData((prevState) => ({
        ...prevState,
        [str]: e,
      }));
    }
    else if (str === "read_privacy_statement") {
      const { checked } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [str]: checked,
      }));
    } else {
      const { value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [str]: value,
      }));
    }
  }

  function handleSubmit() {
    let err = false;
    for (const key in formData) {
      if (key === "email") {
        if (formData[key].split("").includes("@") === false) {
          err = true;
        }
      }
      else if (key === "contact_number") {
        if (!isValidPhoneNumber(formData[key]??'')) {
          err = true;
        }
      }
      if (key === "message" || key === "how_do_you_hear") {
        console.log("");
      } else {
        if (key === "" || false) {
          err = true;
        }
      }
    }
    if (err) {
      setFormErrorShow(true);
    } else {
      setFormErrorShow(false);
      submitData();
    }
  }

  function submitData() {
    const data = { data: formData };
    submitContactForm(data).then((response) => {
      console.log(response);
      if (response) {
        setShowFormInputs(false);
        setShowReqSuccessPopup(true);
      }
    });
  }

  const renderFormLabel = () => {
    return (
      <span>
        By checking this box you are acknowledging that you have read and agree
        to our{" "}
        <a href="/privacy" className="privacystatement">
          privacy statement.
        </a>
      </span>
    );
  };
  return (
    <Modal
      show={true}
      onHide={() => handlehide()}
      centered
      size="lg"
      className="contact-us-modal"
    >
      {showFormInputs ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Get in touch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  placeholder="Name*"
                  className="jobFormInputs"
                  onChange={(e, str) => updateData(e, "name")}
                  value={formData.name}
                />
              </Form.Group>
              <Form.Group className="mb-3 form-flex">
                <input
                  type="text"
                  placeholder="Organization*"
                  className="jobFormInputs"
                  onChange={(e, str) => updateData(e, "organization")}
                  value={formData.organization}
                />
                <input
                  type="text"
                  placeholder="Job title*"
                  className="jobFormInputs"
                  onChange={(e, str) => updateData(e, "job_title")}
                  value={formData.job_title}
                />
              </Form.Group>
              <Form.Group className="mb-3 form-flex">
                <input
                  type="text"
                  placeholder="Email address*"
                  className="jobFormInputs"
                  onChange={(e, str) => updateData(e, "email")}
                  value={formData.email}
                />
                <PhoneInput
                  placeholder="Contact number*"
                  value={formData.contact_number}
                  className="jobFormInputs"
                  onChange={(e) => updateData(e, "contact_number")}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  placeholder="How did you hear about us?"
                  className="jobFormInputs"
                  onChange={(e, str) => updateData(e, "how_do_you_hear")}
                  value={formData.how_do_you_hear}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="textarea"
                  placeholder="Your message"
                  className="jobFormInputs"
                  style={{ resize: "none" }}
                  onChange={(e, str) => updateData(e, "message")}
                  value={formData.message}
                />
              </Form.Group>
              {formErrorShow ? (
                <p className="contactformError">
                  Please fill all the required fields with valid information!
                </p>
              ) : null}
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label={renderFormLabel()}
                  className="contact-us-checkbox"
                  onChange={(e, str) => updateData(e, "read_privacy_statement")}
                  value={formData.read_privacy_statement}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <IneractiveBtn title={"Submit"} handleRoute={() => handleSubmit()} />
          </Modal.Footer>
        </>
      ) : null}
      {showReqSuccessPopup ? (
        <>
          <div className="popUpContainer">
            <div className="checkCircle">
              <img src={ContactCheckCircle} alt={"ContactCheckCircle"} />
            </div>
            <div className="popUpMessage">
              <p>Thank you for reaching out to us!</p>
              <p>We will get in touch shortly.</p>
            </div>
            <div className="popUpClose">
              <button onClick={() => handlehide()}>
                Close <img src={ContactCheckClose} alt="" />
              </button>
            </div>
          </div>
        </>
      ) : null}
    </Modal>
  );
};
