import "../scss/csrPage.scss";
import React, { useState, useEffect} from "react";
import { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import CloseBtn from "../assets/CsrPage/closeBtn.svg";
import HospitalImgCard1 from "../assets/CsrPage/hospital1.png";
import HospitalImgCard2 from "../assets/CsrPage/hospital2.png";
import HospitalImgCard3 from "../assets/CsrPage/hospital3.png";
import HospitalImgCard4 from "../assets/CsrPage/hospital4.png";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import MakingDiffImg from "../assets/CsrPage/makingDiffImg.png";
import Arrow from "../assets/CsrPage/Arrow.svg";
import { GalleryCsr } from "./GalleryCsr";

export const CsrBanner = ({ media }) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [ultraWide, setUltraWide] = useState()
  const [mobile, setMobile] = useState()
  const [normalVW, setNormanVW] = useState()
  const [smallVW,setSmallVW]=useState()

  useEffect(() => {
    const handleResize = () => {
      setUltraWide(window.innerWidth > 2220);
      setNormanVW(window.innerWidth < 2220);
      setSmallVW(window.innerWidth < 1366);
      setMobile(window.innerWidth <= 992);


    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  if (media.csr_media === undefined) return null
  return (
    <div className="csrBannerContainer">
      <div className="csrBannerChildContainer">
        <div className="csrBannerEle">
          <div className="csrBannerHeading">We're more than just business</div>
          <div className="csrBannerDescription">
            At Ngenux, our goal is to create a ripple effect that extends beyond
            our organization and helps to improve the lives of those around us.
          </div>
          <div className="carouselContainerCsr">
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={mobile ? 1 : (smallVW ? 2 : (ultraWide ? 4 : 3))}
              centeredSlides={false}
              spaceBetween={30}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {media.csr_media.map((ind,i) => {
                return (

                  <SwiperSlide key={i}>
                    <div className="carouselCsrBannerImgContainer">
                      <img
                        className="carouselCsrBannerImg"
                        src={process.env.REACT_APP_BASE_LINK + ind.url}
                        alt="img"
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MakingMeaningfulDiff = () => {
  return (
    <div className="makingMeaningfulDiffContainer">
      <div className="makingMeaningfulDiffBox">
        <img className="makingDiffImg" src={MakingDiffImg} alt="" />
        <div className="rightSectionMakingMeaningfulDiffBox">
          <div className="makingTxtHeading">Making a meaningful difference</div>
          <div className="makingTxtDescription">
            Building trust in our community is crucial. We demonstrate our
            commitment by providing nourishing meals to those coping with loved
            ones battling life-threatening illnesses.
            <br />
            <br />
            Ngenux has joined forces with Vandyam to provide home-cooked meals
            to patients' caretakers in the casualty wards of hospitals in
            Bangalore.
          </div>
        </div>
      </div>
    </div>
  );
};

export const Caring = ({media}) => {
  const [gallery, setGallery] = useState(false);
  var originalScrollPos = window.scrollY || window.pageYOffset;

  useEffect(() => {
    document.body.style.overflow = gallery ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [gallery]);

  
  function showGallery() {
    setGallery(!gallery);
    if (!gallery) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, originalScrollPos);
    }
  
  }

  const GalleryFirstFourImages = media.csr_media.map((e)=>{
    return process.env.REACT_APP_BASE_LINK + e.url
  })

  
  return (
    <div  className="caringContainer">
      <div id="topOfcomponent" className="caringHeading">Caring for caretakers</div>
      <div className="caringSubHeading">
        We serve free home-cooked meals to families who are experiencing
        overwhelming situations. We understand that these individuals may not
        have the chance to prepare for such traumatic events, and that's why we
        are here to lend a helping hand.
      </div>
      <div className="caringImgBox">
        <div className="caringImg1">
          <img className="caringImgEle" src={GalleryFirstFourImages[0]} alt="img1" />
        </div>
        <div className="leftImgStrip">
          <div>
            <img className="caringImg2" src={GalleryFirstFourImages[1]} alt="img2" />
          </div>
          <div className="flexRowCaringImg">
            <div>
              <img
                className="caringImg3"
                src={GalleryFirstFourImages[2]}
                alt="img3"
              />
            </div>
            <div onClick={showGallery} className="img4Box">
              <img
                className="caringImg4"
                src={GalleryFirstFourImages[3]}
                alt="img4"
              />
              <div className="viewGalleryTxt">
                View Gallery
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="caringSubHeading">
        For the last two years, we have provided 200 free meals daily to people
        who travel far away to take care of their loved ones. These meals are
        provided to those facing emergency situations in an unfamiliar city who
        need help navigating through unforeseen circumstances.
      </div>
      {gallery ? <GalleryCsr media={media} /> : null}
      {gallery ? (
        <img
          onClick={showGallery}
          className="galleryBtnClose"
          src={CloseBtn}
          alt="close"
        />
      ) : null}
    </div>
  );
};

export const Hospitals = () => {
  return (
    <div className="hospitalContainer">
      <div className="hospitalHeading">Hospitals we serve</div>
      <div className="hospitalDescription">
        We offer meal services at multiple leading hospitals in Bangalore. Our
        food is served at...
      </div>
      <div className="cardHospitalsContainer">
        <div className="hospitalCard">
          <img
            className="hospitalCardImg"
            src={HospitalImgCard1}
            alt="hospitalImg"
          />
          <div className="hospitalTitle">Victoria Hospital</div>
        </div>
        <div className="hospitalCard">
          <img
            className="hospitalCardImg"
            src={HospitalImgCard2}
            alt="hospitalImg"
          />
          <div className="hospitalTitle">St. Martha’s Hospital</div>
        </div>
        <div className="hospitalCard">
          <img
            className="hospitalCardImg"
            src={HospitalImgCard3}
            alt="hospitalImg"
          />
          <div className="hospitalTitle">Sanjay Gandhi Memorial Hospital</div>
        </div>
        <div className="hospitalCard">
          <img
            className="hospitalCardImg"
            src={HospitalImgCard4}
            alt="hospitalImg"
          />
          <div className="hospitalTitle">NIMHANS Hospital</div>
        </div>
      </div>

      <div className="mealScheduleTitle">Meal Schedule</div>
      <div className="mealDescription">
        We offer 200 meals daily, both in the morning and evening, to assist
        caretakers and families who are dealing with medical crises.
        <br />
        <br />
        We recognize the significance of self-care, particularly when someone is
        looking after a loved one during an unexpected catastrophe. It is during
        these challenging times that we provide our support.
      </div>
    </div>
  );
};
