import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getCaseStudyCustomers } from "../api/customers";
import { Helmet } from "react-helmet";



import Header from "../components/Header";
import { Footer } from "../components/Footer";
import {
  CaseStudyBanner,
  CaseStudyContent,
} from "../components/CaseStudyComponents";

import { getCaseStudy } from "../api/homepage";
import { caseStudySingleSerializer,getTestcaseStudes} from "../utils/serializer";

const CaseStudyPage = () => {
  const [caseStudy, setCaseStudyData] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = Number(queryParams.get("id")) || 1;
  const [errorComp, setError] = useState(false);
  const getCaseStudyDetails = (currentPage) => {
    getCaseStudy(currentPage)
      .then((response) => {
        if (response) {
          setCaseStudyData(caseStudySingleSerializer([response]));
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };
  const CaseStudyCustomers = () => {
    getCaseStudyCustomers()
      .then((response) => {
        if (response) {
          setCaseStudies([...caseStudies, ...getTestcaseStudes(response)]);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getCaseStudyDetails(currentPage);
    }, 1);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      CaseStudyCustomers();
    }, 1);
  }, []);
let SeoTitle = caseStudy.map((e)=>{
  return e.data.seo_title
})
let SeoDescription = caseStudy.map((e)=>{
  return e.data.seo_description
})

  return (
    <div>
       <Helmet>
        <title>{SeoTitle}</title>
        <meta
          name="description"
          content={SeoDescription}
        />
      </Helmet>
      <Header />
      <CaseStudyBanner caseCard={caseStudy} />
      <CaseStudyContent caseCard={caseStudy} caseStudyCompare={caseStudies} />
      <Footer />
    </div>
  );
};

export default CaseStudyPage;
