import axios from "axios";

export const getBanner = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/banners?populate=*"
  
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }

  export const WhatWebring = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/what-we-bring?populate=*"
  
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }


export const getAchivements = async () => {

  
    const url = process.env.REACT_APP_BASE_LINK + "/api/achievements?populate=*&pagination[pageSize]=4"
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }

  export const getTestimonials = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/testimonials?populate=*"
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }

  export const getFeaturedCaseStudy = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/case-studies?populate=*&filters[isfeatured][$eq]=true&pagination[pageSize]=1"
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }


  export const getCaseStudy = async (index) => {


    const url = process.env.REACT_APP_BASE_LINK + `/api/case-studies/${index}?populate=*`
     return  await axios.get(url)
        .then((response) => {
          console.log("casestudy", response.data.data)
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }


  

  export const getTopSolutions = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/solutions?populate=*&filters[isfeatured][$eq]=true&pagination[pageSize]=4"
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }




  // /api/testimonials

  
  ///api/achievements