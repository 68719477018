import { IneractiveBtn } from "./BtnInteractive";
import { useNavigate } from "react-router-dom";
import "../scss/openpositionsCard.scss";

const OpenPositionsCard = ({ jobdata }) => {
  const navigate = useNavigate();

  const convertTime = (time) => {
  const targetDate = new Date(time);
  const currentDate = new Date();
  const timeDifference = currentDate - targetDate;

  const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
  const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor(timeDifference / (1000 * 60));

  if (years > 0) {
    return `Posted ${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months > 0) {
    return `Posted ${months} month${months > 1 ? 's' : ''} ago`;
  } else if (weeks > 0) {
    return `Posted ${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `Posted ${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `Posted ${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return `Posted ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  };

  function handleClick() {
    navigate(`/job/${jobdata.id}/${jobdata.title.split(" ").join("-")}`, {
      state: { jobdata: jobdata },
    });
  }
  
  return (
    <>
      <div className="openPositionsCard">
        <div className="openPositionsCardInfo">
          <div className="openPositionLocation">
            <span>
              {jobdata.location} | {jobdata.work_model}
            </span>
          </div>
          <p className="openPositionDesignation">{jobdata.title}</p>
          <p className="openPositionDescription">{jobdata.description}</p>
        </div>
        <div className="openPositionsCardApply">
          <p>{convertTime(jobdata.createdAt)}</p>
          <IneractiveBtn title={"Apply"} handleRoute={() => handleClick()} />
        </div>
      </div>
    </>
  );
};

export default OpenPositionsCard;
