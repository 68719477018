
import axios from "axios";

export const getCSRPageContent = async () => {


    const url = process.env.REACT_APP_BASE_LINK + `/api/csr-infos/?populate=*`
     return  await axios.get(url)
        .then((response) => {

          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }

