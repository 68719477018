import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import { Testimonials } from "../components/HomepageComponents";
import { Footer } from "../components/Footer";
import { Error } from "../components/ErrorComponent";
import { Helmet } from "react-helmet";

import {
  CaseStudiesCustomers,
  CustomersBanner,
  FeaturedCaseStudies,
} from "../components/CustomersComponents";
import { getTestimonials } from "../api/homepage";
import { getCaseStudyCustomers } from "../api/customers";
import { testimonialSerializer, getTestcaseStudes } from "../utils/serializer";

const Customers = () => {
  const [errorComp, setError] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const CaseStudyCustomers = () => {
    getCaseStudyCustomers()
      .then((response) => {
        if (response) {
          setCaseStudies([...caseStudies, ...getTestcaseStudes(response)]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };

  const getTestimonialsList = () => {
    getTestimonials()
      .then((response) => {
        if (response) {
          setTestimonials([
            ...testimonials,
            ...testimonialSerializer(response),
          ]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getTestimonialsList();
    }, 1);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      CaseStudyCustomers();
    }, 1);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Customer Success Stories & Testimonials- Ngenux Solutions</title>
        <meta
          name="description"
          content="Discover how Ngenux, one of Bengaluru's top Artificial Intelligence companies, transforms businesses through customer success stories and testimonials."
        />
      </Helmet>

      {errorComp ? (
        <Error />
      ) : (
        <>
          {isLoading ? null : (
            <>
              <Header />
              <CustomersBanner />
              <FeaturedCaseStudies AllcaseStudeis={caseStudies} />
              <Testimonials
                testimonials={testimonials}
                title={"How we create value for our clients"}
              />
              <CaseStudiesCustomers AllcaseStudeis={caseStudies} />

              <Footer />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Customers;
