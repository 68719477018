import axios from "axios";

export const getCaseStudyCustomers = async () => {


    const url = process.env.REACT_APP_BASE_LINK + `/api/case-studies/?populate=*`
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }