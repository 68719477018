import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import {
  Caring,
  CsrBanner,
  Hospitals,
  MakingMeaningfulDiff,
} from "../components/CsrpageComponents";
import { getCSRPageContent } from "../api/csr";
import { csrSerializer } from "../utils/serializer";

const CsrPage = () => {
  const [images, setImages] = useState([]);


  const getMediaCsr = () => {
    getCSRPageContent()
      .then((response) => {
        if (response) {
          setImages(...images, ...csrSerializer(response));
        } else {
          console.error("Invalid or missing data in the response");
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
      });
  };

  useEffect(() => {
      getMediaCsr();
  }, []);
if(images.csr_media === undefined)
  {
    return null
  }
  else{
  return (
    <div>
      <Header />
      <CsrBanner media={images} />
      <MakingMeaningfulDiff />
      <Caring  media={images}/>
      <Hospitals />
      <Footer />
    </div>
  );
  }
};

export default CsrPage;
