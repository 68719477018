import React from 'react';
import "../scss/common.scss"

export const Error = () => {
    return (
        <div className='errorTxt'>
            OOPS! Something went wrong. 
        </div>
    );
};

