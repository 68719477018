import React, { lazy, Suspense, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer";
import "../scss/header.scss";
import "../scss/insightsLanding.scss";
import "../scss/common.scss";
import { getInsights } from "../api/insightsLanding";
import {
  featuredInsightsSerializer,
  allInsightsSerializer,
  compareDatesDesc,
} from "../utils/serializer";

const Header = lazy(() => import("../components/Header"));
const Insights = lazy(() => import("../components/InsightsLanding"));

const InsightsLanding = () => {
  const [feInsightsList, setFeInsightsList] = useState([]);
  const [insightsCategoryList, setInsightsCategoryList] = useState([]);
  const [insights, setInsights] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("blog");
  const [loadMoreBtnEnable, setLoadMoreBtnDisable] = useState(false);
  const itemsPerPage = 6;

  const getInsightsDetails = () => {
    getInsights().then((response) => {
      categoryList(response, "blog");
      const data = featuredInsightsSerializer(response).sort(compareDatesDesc);
      console.log(data);
      setInsights(data);
      const feInsightsData = data.filter((item) => item.isfeatured);
      setFeInsightsList(feInsightsData);
      // setIsLoading(false);
    });
  };
  function categoryList(response, type) {
    const data = allInsightsSerializer(response);
    const filteredData = data.sort(compareDatesDesc).filter((item) => item.type === type);
    setInsightsCategoryList(() => filteredData);
    if(filteredData.length <= 6) {
      setLoadMoreBtnDisable(true);
    }
  }

  useEffect(() => {
    getInsightsDetails();
  }, []);


  useEffect(() => {
    const data = [...insights];
    const filteredData = data
      .filter((item) => item.type === categoryFilter)
    setInsightsCategoryList(() => filteredData);
    if(filteredData.length <= 6) {
      setLoadMoreBtnDisable(true);
    }
  }, [categoryFilter]);

  function loadMore() {
    const currentIndex = insightsCategoryList.length;
    const nextItems = insights.slice(currentIndex, currentIndex + itemsPerPage);
    const combinedData = [
      ...insightsCategoryList,
      ...nextItems.filter((item) => item.type === categoryFilter),
    ];

    if (nextItems.length < 6) {
      setLoadMoreBtnDisable(true);
    }
    setInsightsCategoryList(combinedData);
  }

  return (
    <Suspense fallback={"Loading..."}>
      <Helmet>
        
        <title>Insights From Ngenux | AI & Data-Driven Company in Bengaluru</title>
        <meta
          name="description"
          content="Explore Ngenux's insgights - a forefront of innovation as Bengaluru's top AI and data-driven company. Discover our cutting-edge solutions."
        />
      </Helmet>
      <Header />
      <Insights
        insights={feInsightsList}
        filteredinsights={insightsCategoryList}
        categoryfilter={categoryFilter}
        setcategoryfilter={(type) => {
          setCategoryFilter(type);
          setLoadMoreBtnDisable(false);
        }}
        loadmorebtn={() => loadMore()}
        loadmorebtnenable={loadMoreBtnEnable}
      />
      <Footer />
    </Suspense>
  );
};

export default InsightsLanding;
