import React from "react";
import "../scss/journeyCard.scss";
import { IneractiveBtn } from "./BtnInteractive";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";

import "../scss/interactiveBtn.scss";

const JourneyCard = () => {
  const navigate = useNavigate()
  function handleJourneyCardBtn(){
    navigate(NAVIGATION_ROUTES.CAREERS)
    window.scrollTo(0, 0);



  }
  return (
    <div className="journeyContainer" id="journeyContainer">
      <div className="cardParent">
        <div className="cardContainer">
          <div className="journeyText">Be a part of our journey</div>
          <div className="createthebestText">
            Create the best with us while we bring out the best in you.
          </div>
          <div className="footerBtn">
            <IneractiveBtn title={"Explore"} btnType={"noFill"} handleRoute={handleJourneyCardBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyCard;
