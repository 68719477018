export const bannerSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      title: item.attributes.Title,
      createdAt: item.attributes.createdAt,
      cta_info: item.attributes.cta_info,
      url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.media_info.data.attributes.url || "",
      urlMobile:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.mobile_video.data.attributes.url || "",
    };
  });

  return serializedArray;
};

export const whatWebringSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      title: item,
      url: item.attributes.Image.data.map((ind) => {
        return process.env.REACT_APP_BASE_LINK + ind.attributes.url || "";
      }),
    };
  });

  return serializedArray;
};

export const achivementSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      title: item.attributes.title,
      createdAt: item.attributes.createdAt,
      description: item.attributes.description,
    };
  });

  return serializedArray;
};

export const getTestcaseStudes = (response) => {
  const serializedArray = response.map((item) => {
    return {
      brandDetails: item.attributes.data,
      isfeatured: item.attributes.isfeatured,
      id: item.id,
      tag: item.attributes.tag,
      details: item.attributes.details,
      description: item.attributes.description,
      title: item.attributes.title,
      img:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.img.data.attributes.url || "",
      logo:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.logo.data.attributes.url || "",
    };
  });

  return serializedArray;
};

export const testimonialSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      name: item.attributes.Name,
      createdAt: item.attributes.createdAt,
      description: item.attributes.Description,
      designation: item.attributes.Designation,
      url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.Img.data.attributes.url || "",
    };
  });

  return serializedArray;
};

export const caseStudySerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      title: item.attributes.title,
      id: item.id,
      createdAt: item.attributes.createdAt,
      description: item.attributes.description,
      logo_url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.logo.data.attributes.url || "",
      img_url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.img.data.attributes.url || "",
      isfeatured: item.attributes.isfeatured,
      data: item.attributes.data,
      details: item.attributes.details,
      impact: item.attributes.options[0],
      url:
        process.env.REACT_APP_BASE_LINK +
        item.attributes.img.data.attributes.url,
    };
  });

  return serializedArray;
};

export const caseStudySingleSerializer = (response) => {
  const SingleserializedArray = response.map((item) => {
    return {
      tag: item.attributes.tag,
      title: item.attributes.title,
      createdAt: item.attributes.createdAt,
      description: item.attributes.description,
      logo_url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.logo.data.attributes.url || "",
      img_url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.img.data.attributes.url || "",
      isfeatured: item.attributes.isfeatured,
      data: item.attributes.data,
      details: item.attributes.details,
      impact: item.attributes.options[0],
      url:
        process.env.REACT_APP_BASE_LINK +
        item.attributes.img.data.attributes.url,
      postedOn: item.attributes.postedOn || "",
    };
  });

  return SingleserializedArray;
};

export const solutionsSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      id: item.id,
      title: item.attributes.title,
      createdAt: item.attributes.createdAt,
      description: item.attributes.description,
      url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.img.data.attributes.url || "",
      isfeatured: item.attributes.isfeatured,
      offerings: item.attributes.data.offerings,
      info: item.attributes.info,
      innerDescription: item.attributes.inner_description,
      metricsBody: item.attributes.metrics_body,
      metricsTitle: item.attributes.metrics_title,
      metricsCard: item.attributes.metrics,
      chartTitle: item.attributes.chart_title,
      chartDescription: item.attributes.chart_decription,
      ChartImage: item.attributes.chart_img.data,
      stepDetailsDescription: item.attributes.solutionstepdetails,
      loaderBannerHead: item.attributes.stats_title,
      loader1_title: item.attributes.stat_1_title,
      loader1_percentage: item.attributes.Stats_1,
      loader2_title: item.attributes.stat_2_title,
      loader2_percentage: item.attributes.Stats_2,
      Seo: item.attributes.data.seo,
    };
  });
  return serializedArray;
};

export const featuredInsightsSerializer = (response) => {
  const serializedArray = response.map((item) => {
    if (item && item.attributes) {
      return {
        seoTitle: item.attributes.data.seo_title,
        seoDescription: item.attributes.data.seo_description,
        title: item.attributes.title || "",
        publishedAt: item.attributes.publishedAt || "",
        read_time: item.attributes.read_time || "",
        url:
          (process.env.REACT_APP_BASE_LINK || "") +
          (item.attributes.img?.data?.attributes?.url || ""),
        tag: item.attributes.tag || "",
        type: item.attributes.type || "",
        isfeatured: item.attributes.isfeatured || false,
        id: item.id || "",
        content: item.attributes.content || "",
        designation: item.attributes.designation || "",
        written_by: item.attributes.written_by || "",
        postedOn: item.attributes.postedOn || "",
      };
    } else {
      console.error("Invalid item:", item);
      return null;
    }
  });

  return serializedArray.filter((item) => item !== null);
};

export const peopleSeralizer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      name: item.attributes.name,
      createdAt: item.attributes.createdAt,
      designation: item.attributes.designation,
      url:
        process.env.REACT_APP_BASE_LINK +
        item.attributes.img.data.attributes.url,
      linkedin_link: item.attributes.linkedin_link,
      category: item.attributes.category,
      categoryList: item.attributes.category_list,
    };
  });

  return serializedArray;
};

export const allInsightsCard = (response) => {
  const serializedArray = response.map((item) => {
    return {
      seoTitle: item.attributes.data.seo_title,
      seoDescription: item.attributes.data.seo_description,
      title: item.attributes.title,
      publishedAt: item.attributes.publishedAt,
      read_time: item.attributes.read_time,
      url:
        process.env.REACT_APP_BASE_LINK +
          item.attributes.img?.data?.attributes?.url || "",
      tag: item.attributes.tag,
      type: item.attributes.type,
      id: item.id,
      content: item.attributes.content,
      designation: item.attributes.designation,
      written_by: item.attributes.written_by,
      postedOn: item.attributes.postedOn || "",
    };
  });
  return serializedArray;
};

export const allInsightsSerializer = (response) => {
  const serializedArray = response.map((item) => {
    if (item && item.attributes) {
      return {
        seoTitle: item.attributes.data.seo_title,
        seoDescription: item.attributes.data.seo_description,
        title: item.attributes.title || "",
        publishedAt: item.attributes.publishedAt || "",
        read_time: item.attributes.read_time || "",
        url:
          (process.env.REACT_APP_BASE_LINK || "") +
          (item.attributes.img?.data?.attributes?.url || ""),
        tag: item.attributes.tag || "",
        type: item.attributes.type || "",
        id: item.id || "",
        content: item.attributes.content || "",
        designation: item.attributes.designation || "",
        written_by: item.attributes.written_by || "",
        postedOn: item.attributes.postedOn || "",
      };
    } else {
      console.error("Invalid item:", item);
      return null;
    }
  });

  return serializedArray.filter((item) => item !== null);
};

export const openPositionsSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      title: item.attributes.title,
      createdAt: item.attributes.createdAt,
      location: item.attributes.location,
      type: item.attributes.type,
      id: item.id,
      work_model: item.attributes.work_model,
      ctc: item.attributes.CTC,
      description: item.attributes.description,
      techstack: item.attributes.techstack || [],
    };
  });

  return serializedArray;
};

export const csrSerializer = (response) => {
  const serializedArray = response.map((item) => {
    return {
      csr_media: item.attributes.images.data.map((e) => {
        return e.attributes;
      }),
    };
  });

  return serializedArray;
};

// Custom comparator function to sort objects by postedOn dates in descending order
export const compareDatesDesc = (a, b) => {
  const dateA = new Date(a.postedOn);
  const dateB = new Date(b.postedOn);

  // Handle cases where postedOn is an empty string
  if (isNaN(dateA) && isNaN(dateB)) {
    return 0;
  } else if (isNaN(dateA)) {
    return 1;
  } else if (isNaN(dateB)) {
    return -1;
  }

  return dateB - dateA;
};
