import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Footer } from "../components/Footer";
import "../scss/header.scss";
import "../scss/career.scss";
import "../scss/common.scss";
import { getOpenPositions } from "../api/career";
import { getInsights } from "../api/insightsLanding";
import {
  openPositionsSerializer,
  allInsightsSerializer,
  compareDatesDesc,
} from "../utils/serializer";
import { Helmet } from "react-helmet";

import { BannerComponent } from "../components/CareerpageComponents";
import { TalentComponent } from "../components/CareerpageComponents";
import { WhyNgenux } from "../components/CareerpageComponents";
import { OurMetrics } from "../components/CareerpageComponents";
import { OpenPositions } from "../components/CareerpageComponents";
import { InsightsMini } from "../components/CareerpageComponents";
import { GallerySlider } from "../components/CareerpageComponents";
const Header = lazy(() => import("../components/Header"));

const CareerPage = () => {
  const [allOpenPositions, setAllOpenPositions] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);
  const [allInsights, setAllInsights] = useState([]);
  const [loadMoreBtnEnable, setLoadMoreBtnDisable] = useState(false);
  const itemsPerPage = 6;

  const openPostionsRef = useRef(null);

  const getOpenJobPositions = () => {
    getOpenPositions().then((response) => {
      const data = openPositionsSerializer(response);
      setAllOpenPositions(data);
      initialList(data);
      // setIsLoading(false);
    });

    getInsights().then((response) => {
      const data = allInsightsSerializer(response).sort(compareDatesDesc);
      setAllInsights(data);
    });
  };

  function initialList(data, type) {
    const slicedData = data.slice(0, 6);
    setOpenPositions(slicedData);
  }

  useEffect(() => {
    getOpenJobPositions();
  }, []);

  function loadMore() {
    const currentIndex = allOpenPositions.length;
    const nextItems = allOpenPositions.slice(
      currentIndex,
      currentIndex + itemsPerPage
    );
    const combinedData = [...allOpenPositions, ...nextItems];
    if (combinedData.length === currentIndex) {
      setLoadMoreBtnDisable(true);
    }
    setOpenPositions(combinedData);
  }

  function handleScroll(ref) {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <Suspense fallback={"Loading..."}>
      <Helmet>
        <title>Explore Careers at Ngenux AI and Data-Driven Company</title>
        <meta
          name="description"
          content="Explore exciting job opportunities today! And join the future of tech with Ngenux Solutions, a leading AI development company in Bengaluru."
        />
      </Helmet>
      <Header />
      <BannerComponent hdlclick={() => handleScroll(openPostionsRef.current)} />
      <GallerySlider />
      <TalentComponent />
      <WhyNgenux />
      <OurMetrics />
      <OpenPositions
        loadmore={() => loadMore()}
        jobs={openPositions}
        loadmorebtnEnable={loadMoreBtnEnable}
        openPostionsRef={openPostionsRef}
      />
      <InsightsMini insights={allInsights} />
      <Footer />
    </Suspense>
  );
};

export default CareerPage;
