import "../scss/casestudyPage.scss";
import ImgOne from "../assets/homepage/One.svg";
import ImgTwo from "../assets/homepage/Two.svg";
import ImgThree from "../assets/homepage/Three.svg";

import CardPlaceHolder from "../assets/caseStudyPage/cardImg.png";
import ReactMarkdown from "react-markdown";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";

export const CaseStudyBanner = ({ caseCard }) => {
  return (
    <div className="caseStudyBannerContainer">
      <div className="caseStudyChildContainer">
        <div className="caseStudyBanner">
          {caseCard.map((e) => {
            const originalDateString = e.postedOn||e.createdAt;
            const originalDate = new Date(originalDateString);
            const month = originalDate.toLocaleString("en-US", {
              month: "long",
            });
            const day = originalDate.getDate();
            const year = originalDate.getFullYear();
            const formattedDate = `${month} ${day} ${year}`;
            return (
              <div>
                <div className="caseStudybnrHeading">{e.title}</div>
                <div className="bannerCaseStudyPills">
                  {e.tag.toUpperCase()} | {e.data.read_time} | {formattedDate}
                </div>
                <div className="solutionsCaseStudyImg">
                  <img src={e.img_url} alt="bannerImg" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CaseStudyContent = ({ caseCard, caseStudyCompare }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  function handleCaseStudylanding(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAVIGATION_ROUTES.CASESTUDY, search: newSearch });
    window.scrollTo(0, 0);

    setTimeout(() => {
      window.location.reload();
    }, 1);
  }

  const TagName = caseCard.map((e) => {
    return e.tag;
  });

  return (
    <div className="caseStudyContentContainer">
      <div className="caseStudyContenTitle">Impact</div>
      <div className="impactBoxesContainer">
        {/* map here for impact boxes */}
        {caseCard.map((e) => {
          return (
            <div className="impactBox">
              <img className="solutionsImpactImg" src={ImgOne} alt="imgTxt" />

              <div className="solutionsParaTextCard">{e.impact.first}</div>
            </div>
          );
        })}
        {caseCard.map((e) => {
          return (
            <div className="impactBox">
              <img className="solutionsImpactImg" src={ImgTwo} alt="imgTxt" />

              <div className="solutionsParaTextCard">{e.impact.second}</div>
            </div>
          );
        })}
        {caseCard.map((e) => {
         if(e.impact.third === null){
          return <></>
         }
         else
          return (
            <div className="impactBox">
              <img className="solutionsImpactImg" src={ImgThree} alt="imgTxt" />

              <div className="solutionsParaTextCard">{e.impact.third}</div>
            </div>
          );
        })}

        {/* map ends here for impact boxes */}
      </div>
      {/* content boxes start here */}
      <div className="contentCaseStudyBox">
        {caseCard.map((e) => {
          return (
            <>
              <ReactMarkdown>{e.details}</ReactMarkdown>
            </>
          );
        })}
      </div>
      {/* case study starts here----------------------------- */}
      <div className="caseStudyCard2Container">
        <div className="caseStudyCard2ContainerTitle">Case studies</div>
        <div className="caseStudyCardSubContainer">
          {caseStudyCompare
            .filter((e) => e.tag.includes(TagName))
            .map((e) => {
              return (
                <div
                  onClick={() => handleCaseStudylanding(e.id)}
                  className="CaseStudyCard2 pointerMouse"
                >
                  <div className="caseStudyCard2ImgContainer">
                    <img
                      className="caseStudyCard2Img"
                      src={e.img}
                      alt="cardimg"
                    />
                  </div>
                  <div className="caseStudyCard2Title">{e.tag}</div>
                  <div className="caseStudyCard2Description">
                    {e.brandDetails.brandWork}
                  </div>
                  <div className="caseStudyCard2StickerImgContainer">
                  <img
                    className="caseStudyCard2StickerImg"
                    src={e.logo}
                    alt="sticker"
                  />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
