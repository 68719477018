import React, { lazy, Suspense, useState, useEffect } from "react";
import { Footer } from "../components/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "../scss/header.scss";
import "../scss/insightDetails.scss";
import "../scss/common.scss";
import { getInsights } from "../api/insightsLanding";
import {
  compareDatesDesc,
  featuredInsightsSerializer,
} from "../utils/serializer";

const Header = lazy(() => import("../components/Header"));
const InsightDetails = lazy(() => import("../components/InsightsDetails"));

export const slugify = (str) => {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
};

const InsightDetailPage = () => {
  const [insight, setInsight] = useState(null);
  const [allData, setAllData] = useState([]);

  const location = useLocation();

  

  useEffect(() => {
    const getInsightsDetails = async () => {
      getInsights().then((response) => {
        const data = featuredInsightsSerializer(response).sort(compareDatesDesc);
        setAllData(data.filter((item) => item.type === "blog"));
  
        const parsedInsight = data.find(
          (d) => slugify(d.title) === location.pathname.split("/insights/")[1]
        );
        setInsight(parsedInsight);
      });
    };
    getInsightsDetails();
  }, [location]);

  return (
    <Suspense fallback={"Loading..."}>
      {!!insight && 
        <>
          <Helmet>
            <title>{insight.seoTitle}</title>
            <meta
              name="description"
              content={insight.seoDescription}
              data-react-helmet="true"
            />
            <meta
              property="og:title"
              content={insight.seoTitle}
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content={insight.seoDescription}
              data-react-helmet="true"
            />
            <meta
              property="og:image"
              content={insight.url}
              data-react-helmet="true"
            />
            <meta
              property="og:url"
              content={location.href}
              data-react-helmet="true"
            />
            <link rel="icon" href="/favicon.ico" />
          </Helmet>

          <Header />
          <InsightDetails data={insight} relatedInsights={allData} />
          <Footer />
        </>
      }
    </Suspense>
  );
};

export default InsightDetailPage;
