import React from "react";
import "../scss/contactUsButton.scss";
import { useNavigate } from "react-router-dom";
export const ContactusButton = ({ dynamic }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`conactusbtn ${dynamic}`}
      onClick={() => navigate("/contactus")}
    >
      Contact Us
    </div>
  );
};
