import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

import "../scss/footer.scss";
import PlusIcon from "../assets/footer/plus.svg";
import SnakeArrowImg from "../assets/footer/arrowSnake.svg";
import ReversedSnakeArrowImg from "../assets/footer/reversedArrowFooter.svg";
import CardVector from "../assets/footer/vectorCard.svg";
import ClickBtn from "../assets/footer/spotlightBtn.svg";
import { IneractiveBtn } from "../components/BtnInteractive";
import "../scss/interactiveBtn.scss";
import { NAVIGATION_ROUTES, NAV_SUB_ROUTES } from "../constants/routes";
import cutomerFooterIcon from "../assets/customersPage/footerImg.svg";
import insightsFooter from "../assets/InsightsLanding/footerInsights.svg";
import { Email } from "@mui/icons-material";
export const Footer = () => {
  const [email, setEmail] = useState("");

  const postUrl = process.env.REACT_APP_BASE_LINK + "/api/newsletters/";

  const sendEmail = () => {
    const dataObj = { data: { email: email } };

    axios
      .post(postUrl, dataObj)
      .then(() => {
        if (!email.includes("@") || !email.includes(".")) {
          alert("Wrong email entered");
        } else {
          alert("Subscribed successfully");
        }
      })
      .catch((error) => {
        console.error("Error saving email:", error);
        alert("Wrong email entered");
      });
     
        setEmail(""); 
  
  };




  const navigate = useNavigate();
  function handleContactUsBtn() {
    navigate(NAVIGATION_ROUTES.CONTACTUS);
    window.scrollTo(0, 0);
  }
  const getContent = () => {
    if (
      window.location.pathname === NAVIGATION_ROUTES.INSIGHTSLANDING ||
      window.location.pathname === NAVIGATION_ROUTES.INSIGHTSDETAILS
    ) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">
                Your vision
                <img className="plusIcon" src={PlusIcon} alt="plus" />
                our ideas
              </p>
              <span className="businessImapctText">
                Business for the future
              </span>
              <div className="snakeArrow snakeArrowInsights">
                <img
                  className="snakearrowImg"
                  src={insightsFooter}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Let’s take these insights to action.
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.COMPANY) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">People-first approach</p>
              <span className="businessImapctText">Business Growth</span>
              <div className="snakeArrow">
                <img className="snakearrowImg" src={SnakeArrowImg} alt="" />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Partner with us on a journey to build lasting business
            relationships.
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.SOLUTIONS) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">People and process</p>
              <span className="businessImapctText">Business alignment</span>
              <div className="snakeArrow">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={ReversedSnakeArrowImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Let us design and deploy solutions that enable superior performance.
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.CUSTOMERS) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">
                Innovation
                <img className="plusIcon" src={PlusIcon} alt="plus" />
                efficiency
              </p>
              <span className="businessImapctText">Business success</span>
              <div className="snakeArrow">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={cutomerFooterIcon}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Things get better when customers experience ingenuity with Ngenux.{" "}
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.CAREERS) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">Collective intelligence</p>

              <span className="businessImapctText">Business success</span>
              <div className="snakeArrow snakeArrowCareers">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={SnakeArrowImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Discover with us the key to driving growth for businesses
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.CSR) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">Shared values</p>

              <span className="businessImapctText">Creating impact</span>
              <div className="snakeArrow">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={SnakeArrowImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Join hands with us as we seek to address challenges within our
            communities.
          </div>
        </>
      );
    } else if (window.location.pathname === NAV_SUB_ROUTES.SOLUTION_DETAIL) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">Innovation that provides</p>

              <span className="businessImapctText">Business solutions</span>
              <div className="snakeArrow">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={cutomerFooterIcon}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            We care about the impact our work creates. Let us drive that impact
            for you too.
          </div>
        </>
      );
    } else if (window.location.pathname === NAVIGATION_ROUTES.CASESTUDY) {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">Collective intelligence</p>

              <span className="businessImapctText">Business success</span>
              <div className="snakeArrow">
                <img
                  className="snakearrowImg snakeArrowSolutions"
                  src={SnakeArrowImg}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Discover with us the key to driving growth for businesses
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="yourdataOurPeopleContainer">
            <div>
              <p className="yourDataText">
                Your data
                <img className="plusIcon" src={PlusIcon} alt="plus" />
                Our people
              </p>
              <span className="businessImapctText">Business Impact</span>
              <div className="snakeArrow snakeArrowInsightDetails">
                <img
                  className="snakearrowImg"
                  src={SnakeArrowImg}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
          <div className="accelerateConatiner">
            Accelerate problem-solving. Let us show you how AI & Data can
            accelerate business.
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {window.location.pathname === NAVIGATION_ROUTES.CONTACTUS ? (
        <div className="footerContainer contactUsFooter">
          <div className="footerContactInfo">
            <div className="tradeMark deskTrademark">
              © 2023 Ngenux Website. All rights reserved
            </div>
            <div className="linksFooter">
              <Link to="../privacy">Privacy Policy</Link>
              <Link to="../privacy_cookies">Cookies</Link>
              <Link to="../termsofuse">Terms of Use</Link>
              <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a>
            </div>
            <div className="tradeMark mobTrademark">
              © 2023 Ngenux Website. All rights reserved
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="footerContainer">
            <div className="footerEle">
              <div className="leftSectionFooter">
                {getContent()}
                {window.location.pathname === NAVIGATION_ROUTES.CAREERS ? (
                  <>
                    <div
                      onClick={handleContactUsBtn}
                      className="footerBtnC footerBtnCareer"
                    >
                      <IneractiveBtn
                        title={"Contact Us"}
                        handleRoute={() => navigate("/contactus")}
                      />
                    </div>
                  </>
                ) : (
                    <div onClick={handleContactUsBtn} className="footerBtnC">
                      <IneractiveBtn
                        title={"Contact Us"}
                        handleRoute={() => navigate("/contactus")}
                      />
                    </div>
                )}
              </div>
              <div className="rightSectionFooter">
                <div className="cardSpotlight">
                  <div className="spotlightText">Spotlight</div>
                  <div className="subscribeText">
                    Subscribe to our newsletter for CXO-focused insights from
                    global AI experts.
                  </div>
                  <div className="inputSection">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Your email address"
                    />
                    <div className="clickBtnSportlight" >
                      <img
                        onClick={sendEmail}
                        className="arrowbuttonInput"
                        src={ClickBtn}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="cardvectorImg">
                    <img
                      className="cardvectorImgInner"
                      src={CardVector}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footerContactInfo">
              <div className="tradeMark deskTrademark">
                © 2023 Ngenux Website. All rights reserved
              </div>
              <div className="linksFooter">
                <Link to="../privacy">Privacy Policy</Link>
                <Link to="../privacy_cookies">Cookies</Link>
                <Link to="../termsofuse">Terms of Use</Link>
                <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a>
              </div>
              <div className="tradeMark mobTrademark">
                © 2023 Ngenux Website. All rights reserved
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
