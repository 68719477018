import Homepage from "./pages/Homepage";
import { NAVIGATION_ROUTES, NAV_SUB_ROUTES, LEGACY_ROUTES_MAP } from "./constants/routes";
import SolutionsLandingPage from "./pages/SolutionsLandingPage";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SolutionsDetailPage from "./pages/SolutionsDetailPage";
import CaseStudyPage from "./pages/CaseStudyPage";
import CsrPage from "./pages/CsrPage";
import Customers from "./pages/Customers";
import { CompanyPage } from "./pages/Companypage";
import InsightsLanding from "./pages/InsightsLanding";
import InsightDetails from "./pages/InsightDetails";
import Career from "./pages/Career";
import JOBDETAILS from "./pages/JobDetails";
import ContactUs from "./pages/ContactUs";
import { Helmet } from "react-helmet";
import colors from "./constants/colors";
import PageNotFound from "./components/PageNotFound";
import Privacy from "./pages/Privacy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  const { background } = colors;

  return (
    <>
      <Helmet
        bodyAttributes={{ style: `background-color :${background.default}` }}
      >
        <meta property="og:title" content="Ngenux" />
        <meta
          property="og:description"
          content="Enable Digital Transformation @ Ngenux"
        />
        <meta
          property="og:image"
          content="https://www.ngenux.com/static/media/ngenuxlogoDesk.e98497b0accbe64cd901cdaedb12051e.svg"
        />
        <meta property="og:url" content="ngenux.com "/>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          {Object.keys(LEGACY_ROUTES_MAP).map((p) => (
            <Route
              path={p}
              element={<Navigate to={LEGACY_ROUTES_MAP[p]} exact />}
            />
          ))}
          <Route path={NAVIGATION_ROUTES.HOMEPAGE} element={<Homepage />} />
          <Route
            path={NAVIGATION_ROUTES.SOLUTIONS}
            element={<SolutionsLandingPage />}
          />
          <Route
            path={NAV_SUB_ROUTES.SOLUTION_DETAIL}
            element={<SolutionsDetailPage />}
          />
          <Route
            path={NAVIGATION_ROUTES.CASESTUDY}
            element={<CaseStudyPage />}
          />
          <Route
            path={NAVIGATION_ROUTES.CSR}
            element={<CsrPage />}
          />
          <Route
            path={NAVIGATION_ROUTES.CUSTOMERS}
            element={<Customers/>}
          />
          <Route path={NAVIGATION_ROUTES.COMPANY} element={<CompanyPage />} />
          <Route
            path={NAVIGATION_ROUTES.INSIGHTSLANDING}
            element={<InsightsLanding />}
          />
          <Route
            path={NAVIGATION_ROUTES.INSIGHTSDETAILS}
            element={<InsightDetails />}
          />
          <Route path={NAVIGATION_ROUTES.CAREERS} element={<Career />} />
          <Route path={NAVIGATION_ROUTES.PRIVACY_COOKIES} element={<Privacy />} />
          <Route path={NAVIGATION_ROUTES.PRIVACY} element={<PrivacyPolicy />} />
          <Route path={NAVIGATION_ROUTES.TERMS} element={<TermsOfUse />} />

          
          <Route
            path={NAVIGATION_ROUTES.JOBDETAILS}
            element={<JOBDETAILS />}
          />
          <Route path={NAVIGATION_ROUTES.CONTACTUS} element={<ContactUs />} />

          <Route path="*" element={<PageNotFound />}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
