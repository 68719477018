import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IneractiveBtn } from "./BtnInteractive";
import OpenPositionsCard from "./OpenPositionsCard";
import SingleInsight from "./SingleInsight";
import VideoPlayer from "./VideoPlayer";
import Banner_BG from "../assets/career/banner_bg.svg";
import landingBG from "../assets/InsightsLanding/fedInsights_bg.png";
import WhyNgenuxVideoBanner from "../assets/career/whyNgenux.webp";
import TeamworkIcon from "../assets/career/Teamwork.svg";
import ConsistencyIcon from "../assets/career/Consistency.svg";
import BalanceIcon from "../assets/career/Balance.svg";
import LoadCircle from "../assets/InsightsLanding/load.svg";
import { slugify } from "../pages/InsightDetails";

export const BannerComponent = ({ hdlclick }) => {
  return (
    <div className="careerBannerContainer">
      <img src={Banner_BG} alt="career banner" className="career_banner" />
      <div className="careerBannerContent">
        <div className="careerBannerTitle">Let’s create together</div>
        <div className="careerBannerTagline">
          Come on board as we collaborate on solving complex challenges
          creatively
        </div>
        <div className="careerOpportunitiesBtn">
          <IneractiveBtn
            title={"View Opportunities"}
            handleRoute={() => hdlclick()}
          />
        </div>
      </div>
    </div>
  );
};

export const GallerySlider = () => {
  const [isClicked, setIsClicked] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [firstEleStyle, setFirstEleStyle] = useState(true);

  function handleClick(num) {
    setFirstEleStyle(false);
    const updatedState = {};
    Object.keys(isClicked).forEach((key) => {
      updatedState[key] = key === num;
    });
    setIsClicked(updatedState);
  }

  return (
    <div className="gallery-container">
      <div className="career-gallery">
        <div
          className={`gallery-img ${firstEleStyle ? "gallery-img-first" : ""} ${
            isClicked[1] ? "gallery-img-clicked" : ""
          }`}
          onClick={() => handleClick("1")}
        >
          <div className="gallery-img-content">
            <p>
              We have an amazing work culture. The employees grow together, have
              fun, share their goals, and collaborate to achieve them.
            </p>
            <p>Nihal Yaseen Baig, Software Engineering Team</p>
          </div>
        </div>
        <div
          className={`gallery-img ${isClicked[2] ? "gallery-img-clicked" : ""}`}
          onClick={() => handleClick("2")}
        >
          <div className="gallery-img-content">
            <p>
              Ngenux’s inclusive leadership style, guidance and encouragement
              have been pivotal in my growth.
            </p>
            <p>Bhanu Prakash Dasari, – Senior Data Architect</p>
          </div>
        </div>
        <div
          className={`gallery-img ${isClicked[3] ? "gallery-img-clicked" : ""}`}
          onClick={() => handleClick("3")}
        >
          <div className="gallery-img-content">
            <p>
              Ngenux encourages employees to learn and grow, and I've been able
              to advance in my career as a result.
            </p>
            <p>Srinith Reddy Gujula, Software Engineering Team</p>
          </div>
        </div>
        <div
          className={`gallery-img ${isClicked[4] ? "gallery-img-clicked" : ""}`}
          onClick={() => handleClick("4")}
        >
          <div className="gallery-img-content">
            <p>
              If you want to foster your growth, boost your skills, open career
              doors, and have work-life balance, Ngenux is your ideal choice.
            </p>
            <p>Daya Reddy Challa, Artificial Intelligence Team</p>
          </div>
        </div>
        <div
          className={`gallery-img ${isClicked[5] ? "gallery-img-clicked" : ""}`}
          onClick={() => handleClick("5")}
        >
          <div className="gallery-img-content">
            <p>
              Ngenux values and rewards hard work. There’s a system to recognize
              and appreciate outstanding contributions.
            </p>
            <p>Venkat Ram Terli, Business Intelligence Team</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TalentComponent = () => {
  return (
    <div className="talentContainerParent">
      {/* <img src={landingBG} alt="talent banner" className="career_banner" /> */}
      <div className="talentContainer">
        <div className="talentContentLeft">
          <div className="talentContentLeftTitle">
            You are more than just talent!
          </div>
          <div className="talentContentLeftTagline">
            <p>
              Ngenux prioritizes its people. We offer the best opportunities to
              the best talent and turn them into high caliber rockstars.
            </p>
            <p>
              Looking for a purpose in what you do? We are here to provide you
              just that!
            </p>
          </div>
        </div>
        <div className="talentContentRight">
          <div className="talentItemTypeContainer">
            <p className="talentItemType">Passion</p>
            <p className="talentItemTypeContent">
              Curiosity builds passion. We exude more than just an interest in
              what we do.
            </p>
          </div>
          <div className="talentSeperator"></div>
          <div className="talentItemTypeContainer">
            <p className="talentItemType">Learnability</p>
            <p className="talentItemTypeContent">
              Every project is an opportunity. We strive to learn and innovate as
            much as we can.
            </p>
          </div>
          <div className="talentSeperator"></div>
          <div className="talentItemTypeContainer">
            <p className="talentItemType">Experimentation</p>
            <p className="talentItemTypeContent">
              Unique challenges need creatiive approach. We are always eager to
              adapt and evolve.
            </p>
          </div>
          <div className="talentSeperator"></div>
        </div>
      </div>
    </div>
  );
};

export const WhyNgenux = () => {
  return (
    <div className="whyNgenuxContainer">
      <div className="whyNgenuxTitle">
        <p>Why Ngenux</p>
        <p>
          Our goal is to make a difference in this world through AI & Data.
          Let’s join forces.
        </p>
      </div>
      <div className="whyNgenuxVideo">
        <div className="whyNgenuxVideoBanner">
          <VideoPlayer videoSource={""} previewImage={WhyNgenuxVideoBanner} />
        </div>
      </div>
    </div>
  );
};

export const OurMetrics = () => {
  return (
    <div className="ourMetricsContainer">
      <div className="ourMetricsTitle">
        <p>Our metrics</p>
        <p>Your success drives Ngenux</p>
      </div>
      <div className="ourMetricsContent">
        <div className="ourMetricsContentItem">
          <img src={TeamworkIcon} alt="" />
          <p>Teamwork</p>
        </div>
        <div className="ourMetricsContentItem">
          <img src={ConsistencyIcon} alt="" />
          <p>Consistency</p>
        </div>
        <div className="ourMetricsContentItem">
          <img src={BalanceIcon} alt="" />
          <p>Balance</p>
        </div>
      </div>
    </div>
  );
};

export const OpenPositions = ({
  loadmore,
  jobs,
  loadmorebtnEnable,
  openPostionsRef,
}) => {
  return (
    <div className="openPositionsContainer" ref={openPostionsRef}>
      <img src={Banner_BG} alt="career banner" className="career_banner" />
      <div className="openPositionsTitle">
        <p>Open positions</p>
        <p>
          We focus on people who make a difference. Join us to make a
          difference.
        </p>
      </div>
      <div className="openPositionsContent">
        <div className="openPositionsContentBox">
          {jobs && jobs.map((job) => <OpenPositionsCard jobdata={job} />)}
        </div>
        {jobs && jobs.length > 5 && !loadmorebtnEnable ? (
          <div className="openPositionsLoadMore" onClick={() => loadmore()}>
            <span>
              Load More
              <img
                className="arrowInteractiveTransform"
                src={LoadCircle}
                alt=""
              />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const InsightsMini = ({ insights }) => {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/insights");
  }

  function insightsClick(data) {
    navigate(`/insights/${slugify(data.title).split(" ").join("-")}`, {
      state: { insightData: data },
    });
  }
  return (
    <div className="insightsMiniContainer">
      <div className="insightsMiniTitle">
        <p>Insights</p>
        <p>Read our stories to understand where Ngenux wants to go next</p>
      </div>
      <div className="insightsMiniContent">
        <div className="insightsMiniContentItems">
          {insights &&
            insights
              .slice(0, 3)
              .map((insight) => (
                <SingleInsight
                  item={insight}
                  click={(item) => insightsClick(item)}
                />
              ))}
        </div>
        <div className="insightsViewAll">
          <IneractiveBtn title={"View All"} handleRoute={() => handleClick()} />
        </div>
      </div>
    </div>
  );
};
