import React, { useState } from "react";
import { useEffect } from "react";
import "../scss/insights.scss";
import SingleInsight from "./SingleInsight";
import { IneractiveBtn } from "./BtnInteractive";
import { NAVIGATION_ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import "../scss/insights.scss";
import { allInsightsCard, compareDatesDesc } from "../utils/serializer";
import { getInsights } from "../api/insightsLanding";
import { slugify } from "../pages/InsightDetails";

const Insights = () => {
  const [insights, setInsightsData] = useState([]);
  const navigate = useNavigate();
  const getInsightsCard = () => {
    getInsights().then((response) => {
      setInsightsData(
        [...insights, ...allInsightsCard(response)].sort(compareDatesDesc)
      );
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getInsightsCard();
    }, 1000);
  }, []);
  function handleInsightsBtn() {
    navigate(NAVIGATION_ROUTES.INSIGHTSLANDING);
    window.scrollTo(0, 0);
  }

  function handleClick(data) {
    navigate(`/insights/${slugify(data.title).split(" ").join("-")}`, {
      state: { insightData: data, relatedInsights: insights },
    });
  }
  if (insights === null || undefined) return <></>;
  if (insights.length === 0) {
    return <></>;
  } else {
    return (
      <div className="insightsContainer">
        <div className="insightsHeading">Insights</div>
        <div className="greatIdeasTextBox">
          <p className="greatIdeasText">Great ideas are led by experts </p>
          <p className="seewhatText">See what our experts say</p>
        </div>

        <div className="cardInsightsContainer">
          {insights.slice(0, 3).map((item) => {
            return (
              <SingleInsight item={item} click={(item) => handleClick(item)} />
            );
          })}
        </div>

        <IneractiveBtn title={"View all"} handleRoute={handleInsightsBtn} />
      </div>
    );
  }
};

export default Insights;
