import "../scss/solutionsLandingPage.scss";
import CardImgWork1 from "../assets/solutionsLandingPage/workCardImg.svg";
import CardImgWork2 from "../assets/solutionsLandingPage/workCardImg2.svg";
import CardImgWork3 from "../assets/solutionsLandingPage/workCardImg3.svg";
import LineDiagram from "../assets/solutionsLandingPage/Image (2).png"
import LineDiagram2 from "../assets/solutionsLandingPage/Image (3).png"


import { OurSolutionsCard } from "./OurSolutionsCard";

export const HeroBanner = () => {
  return (
    <div className="heroContainerSolutionsParent">
      <div className="heroContainerSolutions">
        <div className="bannerSolutionContainer">
          <div className="dataDrivenText">
            Data-driven future,{" "}
            <span className="poweredbyaiText">powered by AI</span>
          </div>
          <div className="withNgenuxText">
            With Ngenux, AI-driven data insights shape your business growth and
            success
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhatDefinesOurWork = () => {
  return (
    <div className="whatDefinesOurWorkContainer">
      <div className="definesText">What defines our work</div>
      <div className="cardWorkContainer">
        <div className="workCard">
          <img className="cardImgWork" src={CardImgWork1} alt="workImg" />
          <div className="workCardTitle"> Quick turnaround</div>
          <div className="workCardFooter">
            {" "}
            Faster execution and value realization
          </div>
        </div>
        <div className="workCard">
          <img className="cardImgWork" src={CardImgWork2} alt="workImg" />
          <div className="workCardTitle">Holistic team</div>
          <div className="workCardFooter">
            {" "}
            Experts who possess technical and business capabilities​
          </div>
        </div>
        <div className="workCard">
          <img className="cardImgWork" src={CardImgWork3} alt="workImg" />
          <div className="workCardTitle">KPI-linked Data Architecture</div>
          <div className="workCardFooter">
            {" "}
            Solutions designed to amplify business value.
          </div>
        </div>
      </div>
    </div>
  );
};

export const OurSolutions = ({ cardData }) => {
  return (
    <div className="ourSolutionsContainer">
      <div className="ourSolutionsText">Our solutions</div>
      <div className="empoweringText">Empowering your data for you</div>
      {cardData.map((e, index) => {
        return <OurSolutionsCard cardData={e} number={index % 2} />;
      })}
    </div>
  );
};

export const Methodology = () => {
  return (
    <div className="methodologyContainer">
      <div className="ourSolutionsText">Methodology</div>
      <div className="empoweringText helperclassActivateText">
        We activate and connect collective intelligence
      </div>
      <div>
        <img className="methodologyImg" src={LineDiagram} alt="linediagrem" />
        <img className="methodologyImgMobile" src={LineDiagram2} alt="linediagrem" />
      </div>
    </div>
  );
};
