import { slugify } from "../pages/InsightDetails";

const NAVIGATION_ROUTES = {
  HOMEPAGE: "/",
  SOLUTIONS: "/solutions",
  CUSTOMERS: "/customers",
  COMPANY: "/company",
  CAREERS: "/careers",
  INSIGHTSLANDING: "/insights",
  INSIGHTSDETAILS: "/insights/:id",
  CONTACTUS: "/contactus",
  CASESTUDY: "/case-study",
  CSR: "/csr",
  JOBDETAILS: "/job/:id/:id",
  PRIVACY_COOKIES: "/privacy_cookies",
  PRIVACY: "privacy",
  TERMS: "termsofuse",
};
const NAV_SUB_ROUTES = {
  SOLUTION_DETAIL: "/solutions/detail",
};

const LEGACY_ROUTES_MAP = {
  "/blogs/microfrontends-revolutionizing-web-development":
    "/insights/" + slugify("Microfrontends:-Revolutionizing-Web-Development"),
  "/blogs/empowering-sales-reps-with-ai-transforming-the-sales-landscape":
    "/insights/" +
    slugify("Empowering-Sales-Reps-with-AI:-Transforming-the-Sales-Landscape"),
  "/blogs/customer-success": "/insights/" + slugify("Customer-Success"),
  "/blogs/unleash-the-power-of-dataops-streamlining-decision-making-in-the-digital-age":
    "/insights/" +
    slugify(
      "Unleash-the-power-of-DataOps:-Streamlining-Decision-Making-in-the-Digital-Age"
    ),
  "/blogs/choosing-the-right-bi-tool-a-deep-dive-into-the-top-5":
    "/insights/" +
    slugify("Choosing-the-Right-BI-Tool:-A-Deep-Dive-into-the-Top-5"),
  "/blogs/the-power-of-now-how-near-realtime-data-streaming-helped-improve-customer-experience":
    "/insights/" +
    slugify(
      "The-Power-of-Now:-Improving-Customer-Experience-with-Near-Realtime-Data-Streaming"
    ),
  "/blogs/data-mesh-a-new-paradigm-for-data-engineering":
    "/insights/" + slugify("Data-Mesh:-A-new-paradigm-for-Data-Engineering"),
  "/blogs/data-observability-everything-you-need-to-know":
    "/insights/" + slugify("Data-Observability:-Everything-you-need-to-know!"),
  "/blogs/how-machine-learning-is-used-in-pricing-optimisation":
    "/insights/" +
    slugify(
      "Maximizing-Your-Profits-with-Machine-Learning:-The-Benefits-of-Price-Optimization"
    ),
  "/blogs/building-powerful-cyber-security-dashboard":
    "/insights/" + slugify("Building-Powerful-Cyber-Security-Dashboard"),
  "/blogs/fuelling-business-strategies-with-customer-lifetime-value-cltv":
    "/insights/" +
    slugify("fuelling-business-strategies-with-customer-lifetime-value-cltv"),
  "/blogs/how-modern-data-stack-shifted-the-data-engineering-space-for-the-better":
    "/insights/" +
    slugify("How-modern-data-stack-shifted-the-data-engineering-space"),
  "/blogs/dont-trust-your-data-without-verification":
    "/insights/" + slugify("Don’t-trust-your-data-without-verification"),
  "/blogs/using-ai-to-automate-match-making-on-a-freelancer-platform":
    "/insights/" +
    slugify("Using-AI-to-Automate-Match-Making-on-a-Freelancer-Platform"),
  "/blogs/is-your-business-anchored-against-the-data-drift":
    "/insights/" + slugify("Is-your-business-anchored-against-the-data-drift"),
  "/blogs/step-up-your-machine-learning-pipeline-to-drive-business-value":
    "/insights/" +
    slugify("Step-up-your-Machine-Learning-pipeline-to-drive-business-value"),
};

export { NAVIGATION_ROUTES, NAV_SUB_ROUTES, LEGACY_ROUTES_MAP };
