import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Error } from "../components/ErrorComponent";
import { Helmet } from "react-helmet";

import {
  Capabilities,
  CaseStudy,
  Testimonials,
  BannerComponent,
  MerticsBanner,
  WhatWeBring,
} from "../components/HomepageComponents";
import { Footer } from "../components/Footer";
import JourneyCard from "../components/JourneyCard";
import Insights from "../components/Insights";
import {
  getBanner,
  getAchivements,
  getTestimonials,
  getFeaturedCaseStudy,
  getTopSolutions,
  WhatWebring,
} from "../api/homepage";
import {
  bannerSerializer,
  achivementSerializer,
  testimonialSerializer,
  caseStudySerializer,
  solutionsSerializer,
  whatWebringSerializer,
} from "../utils/serializer";

export const Homepage = () => {
  const [bannerList, setBannerList] = useState([]);
  const [achivementsList, setAchivements] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [topSolutions, setTopSolutions] = useState([]);
  const [caseStudy, setCaseStudy] = useState(null);
  const [whatweBring, setwhatweBring] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorComp, setError] = useState(false);

  const getBannerDetails = () => {
    getBanner()
      .then((response) => {
        if (response) {
          setBannerList([...bannerList, ...bannerSerializer(response)]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching banner:", error);
      });
  };

  const getWhatWebringDetails = () => {
    WhatWebring()
      .then((response) => {
        if (response) {
          setwhatweBring([...whatweBring, ...whatWebringSerializer(response)]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching banner:", error);
      });
  };

  const getAchivementsList = () => {
    getAchivements()
      .then((response) => {
        if (response) {
          setAchivements([
            ...achivementsList,
            ...achivementSerializer(response),
          ]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
      });
  };

  const getTestimonialsList = () => {
    getTestimonials()
      .then((response) => {
        if (response) {
          setTestimonials([
            ...testimonials,
            ...testimonialSerializer(response),
          ]);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };

  const getFeatureCaseStudy = () => {
    getFeaturedCaseStudy()
      .then((response) => {
        if (response && response.length > 0) {
          setCaseStudy(caseStudySerializer(response)[0]);
        } else {
          console.error("");
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };

  const getTopSolutionsList = () => {
    getTopSolutions()
      .then((response) => {
        if (response) {
          setTopSolutions([...topSolutions, ...solutionsSerializer(response)]);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching top solutions:", error);
        setError(true);
      });
  };

  useEffect(() => {
    getBannerDetails();
    setTimeout(() => {
      getAchivementsList();
      getTestimonialsList();
      getFeatureCaseStudy();
      getTopSolutions();
      getTopSolutionsList();
      getWhatWebringDetails();
    }, 100);
  }, []);

  let schemaHomePage = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Ngenux Solutions Private Limited",
    alternateName: "Ngenux Solutions",
    url: "https://www.ngenux.com/",
    logo: "https://www.ngenux.com/logo.svg",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "9606723030",
      contactType: "technical support",
      areaServed: ["US", "IN"],
    },
    sameAs: "https://www.linkedin.com/company/ngenux-solutions-pvt-ltd/",
  };
 
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaHomePage)}
        </script>

        <title>Leading AI and Data-Driven Company in Bengaluru- Ngenux</title>
        <meta
          name="description"
          content="Discover how Ngenux empowers Bengaluru's leading companies with cutting-edge AI solutions and data-driven strategies for business success, unlocking the potential of AI and data with expert services."
        />
      </Helmet>

      {errorComp ? (
        <Error />
      ) : (
        <>
          {isLoading ? null : (
            <>
              <Header />
              {!isLoading && <BannerComponent bannerList={bannerList} />}
              <WhatWeBring wwbringCard={whatweBring} />
              {!isLoading && (
                <MerticsBanner achivementsList={achivementsList} />
              )}
              {!isLoading && <Capabilities topSolutions={topSolutions} />}
              {!isLoading && (
                <Testimonials
                  testimonials={testimonials}
                  title={"The impact we create for our clients"}
                />
              )}
              <CaseStudy caseStudy={caseStudy} />
              {!isLoading && <Insights />}
              {!isLoading && <JourneyCard />}
              <Footer />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Homepage;
