import { format } from "date-fns";
import BlueArrow from "../assets/homepage/blueArrow.svg";

const SingleInsight = ({ item, click }) => {
  const ctaType = (type) => {
    const ctaTypes = {
      blog: "Read",
      audio: "Listen",
      video: "Watch",
    };
    return ctaTypes[type];
  };

  const convertTime = (time) => {
    if (time) {
      const timeParts = time.split(":");
      if (timeParts.length === 3) {
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const totalMinutes = hours * 60 + minutes;
        return totalMinutes;
      } else {
        console.log("Invalid time format");
      }
    } else {
      return "";
    }
  };
  return (
    <div className="insightsCatCard pointerMouse" onClick={() => click(item)}>
      <div className="imgBox">
        <img className="insightsImgCard" src={item.url} alt="" />
        <div className="fedInsightsPillCard">
          <div>{item.tag}</div>
        </div>
      </div>
      <div className="insightsCatTextCard">
        <p className="fedInsightsTimeStamp">
          {convertTime(item.read_time)} min |{" "}
          {format(new Date(item.postedOn||item.publishedAt), "MMMM dd, yyyy")}
        </p>
        <p>{item.title}</p>
      </div>
      <div className="fedInsightsBtnCard">
        {ctaType(item.type)}
        <img src={BlueArrow} alt="" />
      </div>
    </div>
  );
};

export default SingleInsight;
