import React, {useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../scss/carouselComp.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

export const TestimonialCarouselComp = ({ testimonials }) => {
  const [ultraWide, setUltraWide] = useState()
  const [mobile, setMobile] = useState()
  const [normalVW, setNormanVW] = useState()
  const [smallVW,setSmallVW]=useState()

  useEffect(() => {
    const handleResize = () => {
      setUltraWide(window.innerWidth > 2220);
      setNormanVW(window.innerWidth < 2220);
      setSmallVW(window.innerWidth < 1366);
      setMobile(window.innerWidth <= 992);


    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  

  const [setSwiperRef] = useState(null);
  return (
    <div className="carouselContainer">
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={mobile ? 1 : (smallVW ? 2 : (ultraWide ? 4 : 3))}
        centeredSlides={false}
        spaceBetween={10}
        loop={false}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
       
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide>
            <div className="carouselCard">
              <div className="carouselTextMain">
               {testimonial.description}
              </div>
              <div className="cardFooter">
                <div>
                  <img className="footercardImg" src={testimonial.url} alt="footerImg" />
                </div>
                <div>
                  <span className="empName">{testimonial.name}</span>
                  <br />
                  <span className="empDesig">
                    {testimonial.designation}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
