import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import landingBG from "../assets/InsightsLanding/fedInsights_bg.png";
import UploadIcon from "../assets/jobDetails/upload.svg";
import ContactCheckCircle from "../assets/ContactUs/check_circle.svg";
import { IneractiveBtn } from "./BtnInteractive";
import { uploadResumeForm, applyJobForm } from "../api/jobDetails";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

export const JobDetails = ({ data }) => {
  const [formData, setFormData] = useState({
    location: "",
    email: "",
    contact: "",
    name: "",
    file: [],
    exp: "",
  });
  const [formErrorShow, setFormErrorShow] = useState(false);
  const [showFormInputs, setShowFormInputs] = useState(true);
  const [showReqSuccessPopup, setShowReqSuccessPopup] = useState(false);
  function convertNumberToINRFormat(number) {
    if (number < 100000) {
      const formattedValue = number % 1 === 0 ? number : number.toFixed(2);
      return `₹${formattedValue}`;
    } else if (number < 10000000) {
      const lakhformat =
        number % 1 === 0 ? number / 100000 : (number / 100000).toFixed(2);
      return `₹${lakhformat} Lakh per annum`;
    } else {
      const croreFormat =
        number % 1 === 0 ? number / 10000000 : (number / 10000000).toFixed(2);
      return `₹${croreFormat} Crore per annum`;
    }
  }

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  function updateData(e, str) {
    if(str==="contact"){
      setFormData((prevState) => ({
        ...prevState,
        [str]: e,
      }));
    }
    else if (str === "file") {
      const { files } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [str]: files[0] || [],
      }));
    } else {
      const { value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [str]: value,
      }));
    }
  }

  function handleSubmit() {
    let err = false;
    for (const key in formData) {
      if (key === "email") {
        if (formData[key].split("").includes("@") === false) {
          err = true;
        }
      }
      else if (key === "contact") {
        if (!isValidPhoneNumber(formData[key]??'')) {
          err = true;
        }
      }
      else if (key === "exp") {
      if (!Number(formData[key])) err=true;
      }
      else if (key === "file") {
        if (formData[key].length === 0) {
          err = true;
        }
      } else {
        if (key === "" || false) {
          err = true;
        }
      }
    }
    if (err) {
      setFormErrorShow(true);
    } else {
      setFormErrorShow(false);
      submitData();
    }
  }

  function submitData() {
    // console.log(formData.file);
    let fileFormdata = new FormData();
    fileFormdata.append("files", formData.file);

    uploadResumeForm(fileFormdata).then((response) => {
      if (response) {
        const obj = { ...formData };
        delete obj["file"];
        obj["file"] = response.id;
        obj["job_listing"] = {
          disconnect: [],
          connect: [
            {
              id: data.id,
              position: {
                end: true,
              },
            },
          ],
        };
        const dataObj = { data: obj };
        applyJobForm(dataObj).then((response) => {
          if (response) {
            setShowFormInputs(false);
            setShowReqSuccessPopup(true);
          }
        });
      }
    });
  }
  return (
    <div className="jobDetailsContainer">
      {/* <img src={landingBG} alt="jobDetailBG" className="jobDetailImg" /> */}
      <div className="jobDetailsHeader">
        <div className="jobDetailsHeadContent">
          <div className="jobDetailsTitle">
            <p>{data.title} at </p>
            <p>Ngenux Solutions - {data.work_model}</p>
          </div>
          <span className="jobDetailsTags">
            {data.location} | {data.work_model} | {data.type} {" "}
            {/* {convertNumberToINRFormat(data.CTC || data.ctc)} */}
          </span>
          <div className="jobDetailsPills">
            {data.techstack &&
              data.techstack.map((stack) => (
                <a href={"/"} alt="">
                  {stack}
                </a>
              ))}
          </div>
        </div>
      </div>
      <div className="jobDetailsContent">
        <div className="jobDetailsmarkdown">
          <ReactMarkdown>{data.description}</ReactMarkdown>
        </div>
        <div className="jobApplyForm">
          {showFormInputs ? (
            <div className="jobForm">
              <div className="jobFormInputsContainer">
                <p>Be the chosen one</p>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name*"
                        className="jobFormInputs"
                        onChange={(e, str) => updateData(e, "name")}
                        value={formData.name}
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email address*"
                        className="jobFormInputs"
                        onChange={(e, str) => updateData(e, "email")}
                        value={formData.email}
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Location (city, country)*"
                        className="jobFormInputs"
                        onChange={(e, str) => updateData(e, "location")}
                        value={formData.location}
                      />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput
                        placeholder="Contact number*"
                        value={formData.contact}
                        className="jobFormInputs"
                        onChange={(e) => updateData(e, "contact")}
                     />
                    </div>
                    <div class="col-md-6 customfile-col">
                      <div className="upload-btn-wrapper">
                        <button
                          className={`upload-btn-wrapper-btn ${
                            formData.file.name ? "upload-file-selected" : ""
                          }`}
                        >
                          {formData.file.name || "Upload resume (Max 2 MB)*"}
                          <img
                            src={UploadIcon}
                            alt="upload icon"
                            className="uploadIcon"
                          />
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={(e, str) => updateData(e, "file")}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Years of experience*"
                        className="jobFormInputs"
                        onChange={(e, str) => updateData(e, "exp")}
                        value={formData.exp}
                      />
                    </div>
                    {formErrorShow ? (
                      <div className="col-md-12 formErrorContainer">
                        <p className="formError">
                          Please fill all the required fields with valid
                          information!
                        </p>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              <div className="jobFormSubmit">
                <IneractiveBtn
                  title={"Apply"}
                  handleRoute={() => handleSubmit()}
                />
              </div>
            </div>
          ) : null}
          {showReqSuccessPopup ? (
            <>
              <div className="jobApplypopUpContainer">
                <div className="checkCircle">
                  <img src={ContactCheckCircle} alt={"ContactCheckCircle"} />
                </div>
                <div className="popUpMessage">
                  <p>Your application is confirmed</p>
                  <p>We will get in touch shortly.</p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
