import React from 'react';
import "../scss/customersPage.scss";


const CaseStudiesCustomersCard = ({title, description, CardImg}) => {
    return (
        <div className='caseStudyCustomersCard'>
            <div className='caseStudiesCustomersCardImgContainer'>
            <img className='caseStudiesCustomersCardImg' src={CardImg} alt='cardImg'/>
            </div>
            <div className='caseStudyCustomersCardTitle'>{title}</div>
            <div className='caseStudyCustomersCardDescription'>{description}</div>
            
        </div>
    );
};

export default CaseStudiesCustomersCard;