import React from "react";
import Header from "../components/Header";
import { JobDetails } from "../components/JobDetailsComponent";
import { Footer } from "../components/Footer";
import { useLocation } from "react-router-dom";
import "../scss/header.scss";
import "../scss/jobDetails.scss";
import "../scss/common.scss";

const JobDetailsMain = () => {
  const { state } = useLocation();

  return (
    <>
      <Header />
      <JobDetails data={state.jobdata}/>
      <Footer />
    </>
  );
};

export default JobDetailsMain;
