import React from 'react';
import { Link } from "react-router-dom";

import "../scss/pageNotFound.scss";
import ErrorImg from '../assets/404.svg';
import { IneractiveBtn } from './BtnInteractive';

const PageNotFound = () => {
    return (
        <div className='pageNotFoundContainer'>
            <img src={ErrorImg} alt='error'/>
            <div className='pageNotFound'>Page not found. Go to</div>
            <div className='weAreSorry'>We’re sorry, the page you requested could not be found.</div>
            <div className='pageNotFoundBtn'><Link to='/'><IneractiveBtn title={"Go to Home"}/></Link></div>
        </div>
    );
};

export default PageNotFound;
