import React from "react";
import "../scss/privacy.scss";
import Header from "../components/Header";
import { Footer } from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <Header/>
    <div className="privacyContainer">
      <div className="privacyHeading">PRIVACY POLICY</div>
      <div className="privacyContent">
        <div>
          Please read this privacy policy carefully before using our website
          operated by NGENUX SOLUTIONS Private Limited.
          <br />
          <br />
        </div>
        <div>
          <strong>Introduction</strong>
          <br />
          <br />
          <p>
            NGENUX SOLUTIONS, Private Limited (“we, “us”, “our”) is pleased that
            you have visited our website and are interested in our company. Your
            privacy is important to us.  For our Website Privacy Policy in
            connection with data we collect on our website, see the Website
            Privacy Policy section. For our Services Privacy Policy in
            connection with NGENUX SOLUTIONS service offerings see the Service
            Privacy Policy section. For any general inquiries related to our
            approach to Privacy, please email connect@ngenux.com.
          </p>
          <br/>
        </div>
        <div>
          <strong>Ngenux website privacy policy</strong>
          <br />
          <br />
          <p>
            This privacy policy will help you understand how NGENUX uses and
            protects the data you provide to us when you visit and use our
            website. We reserve the right to change this policy at any given
            time, of which you will be promptly updated. If you want to make
            sure that you are up to date with the latest changes, we advise you
            to frequently visit the page.
          </p>
          <br/>

        </div>
        <div>
          <strong>Marketing & advertising services for a third party</strong>
          <br />
          <br />
          <p>
            This privacy notice does not cover the activities NGENUX undertakes
            on behalf of its clients - in which it acts as a service provider.
            Should you wish to inquire about the processing we undertake for any
            of our clients, or wish to assert your rights regarding that
            processing, please contact the relevant client directly. NGENUX is a
            business that in some instances will utilize third party data on our
            client&#39;s behalf for marketing or enrichment purposes. You can
            find privacy information related to these services go to Services
            Policy section.
          </p>
          <br/>

        </div>
        <div>
          What is your data we collect?
          <br />
          <br />
          <p>
            <ul>
              <li>Your IP address.</li>
              <li>Your contact information and email address.</li>
              <li>Other information such as interests and preferences.</li>
              <li>
                Data profile regarding your online behavior on our website.
              </li>
            </ul>
          </p>
          <br/>

        </div>
        Why we collect your data?
        <br />
        <br />
        <p>
          <ul>
            <li>To better understand your needs.</li>
            <li>To improve our services and products.</li>
            <li>
              To send you promotional emails containing the information we think
              you will find interesting.
            </li>
            <li>
              To customize our website according to your online behavior and
              personal preferences.
              <br />
              <br />
            </li>
          </ul>
        </p>
        <br/>

        <div></div>
        <div>
          <strong>Who do we share this data with, and why?</strong>
          <br />
          <br />
          <p>
            We do not sell your personal information collected on this website
            to anyone for direct marketing purposes. We may share or utilize
            your information to agents, consultants, and trusted third parties.
            Like many businesses, we sometimes have companies perform certain
            business- related functions for us. These companies include our
            database service providers, backup and disaster recovery service
            providers, email service providers, and others. When we engage
            another company, we may provide them with information including
            personal Information, so they can perform their designated
            functions. They are not permitted to use your Personal Information
            for other purposes. In our business operations which may include
            communicating with you about your relationship with us; promotional
            emails containing information we think you will find interesting;
            corporate restructuring; customize our website according to your
            online behavior and preferences; improving our customer service; and
            disclosure(s) as required by law.
          </p>
          <br/>

        </div>
        <div>
          <strong>How do I exercise my consumer privacy rights?</strong>
          <br />
          <br />
          <p>
            If you wish to update your email marketing preferences, or
            unsubscribe, you can do so by clicking the unsubscribe link in the
            bottom of the email. You can make more specific requests with
            respect to your personal information depending on where you live and
            what you are asking us to do, we may or may not be obligated to
            comply with your request, and/or the time required to complete a
            request may vary:
            <br />
            <br />
          </p>
        </div>
        <div>
          <i>Right to know</i>
          <br />
          <br />
          <p>
            You can request that we disclose to you the categories of personal
            information we collected about you, the categories of sources from
            which we collected the personal information, the categories of
            personal information we sold or disclosed, our business or
            commercial purpose for collecting and selling the personal
            information, the categories of third parties with whom we shared the
            personal information, over the past 12 months.
          </p>
          <br/>

        </div>
        <div>
          <i>Right to access</i>
          <br />
          <br />{" "}
          <p>
            ‍You can request that we provide you with a copy of the specific
            pieces of personal information we collected about you over the past
            12 months.
          </p>
          <br/>

        </div>
        <div>
          <i>Right to deletion</i>
          <br />
          <br />
          <p>
            You can request that we delete your personal information that we
            maintain about you, subject to certain exceptions.
          </p>
          <br/>

        </div>
        <div>
          <i>Do not sell my info.</i>
          <br />
          <br />{" "}
          <p>
            As stated above, we do not sell your personal information collected
            on this website to anyone for direct marketing purposes. However,
            pursuant to California law, you can request that we not sell your
            personal information by clicking the Do Not Sell My Info. For
            purposes of this Privacy Policy, “sell” means the sale of your
            personal information to an outside party for monetary or other
            valuable consideration, subject to certain exceptions set forth in
            applicable California law. Any opt-out of cookie-based tracking for
            advertising purposes is specific to the device, website, and browser
            you are using, and is deleted whenever you clear your browser’s
            cache.
          </p>
          <br/>

        </div>
        <div>
          <strong>Safeguarding and securing the data.</strong>
          <br />
          <br />
          <p>
            NGENUX is committed to securing your data and keeping it
            confidential. We’ve taken all reasonable precautions to safeguard
            all the information we collect online to prevent data theft,
            unauthorized access, and disclosure.
          </p>
          <br/>

        </div>
        <div>
          <strong>Restricting the collection of your personal data.</strong>
          <br />
          <br />
          <p>
            You can achieve this by doing the following: When you are filling
            the forms on the website, make sure to check if there is a box which
            you can leave unchecked, if you don’t want to disclose your personal
            information.
          </p>
          <p>
            If you have already agreed to share your information with us, feel
            free to contact us via email and we will be more than happy to
            change this for you.
          </p>
          <br/>

        </div>
        <div>
          <strong>Links to other websites:</strong>
          <br />
          <br />
          <p>
            Our website contains links that lead to other websites. If you
            click on these links NGENUX is not held responsible for your data
            and privacy protection. Visiting those websites is not governed by
            this privacy policy.
          </p>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
