import React, { useRef, useState } from "react";
import WhyngenuxVideoPlayIcon from "../assets/career/whyngenuxvideo.svg";
import "../scss/videoPlayer.scss";

const VideoPlayer = ({ videoSource, previewImage }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="video-container">
      {!isPlaying ? (
        <video
          ref={videoRef}
          className="video"
          poster={previewImage}
          // onClick={togglePlay}
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          ref={videoRef}
          className="video"
          onClick={togglePlay}
          controls
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!isPlaying && (
        <div className="play-button">
          <img src={WhyngenuxVideoPlayIcon} alt="why ngenux video play icon" />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
