import React from "react";
import BulletPointer from "../assets/solutionsLandingPage/bulletPointer.svg";
import { IneractiveBtn } from "./BtnInteractive";
import "../scss/ourSolutionCard.scss";
import cx from "classnames";
import { NAV_SUB_ROUTES } from "../constants/routes";
import { useNavigate, useLocation } from "react-router-dom";

export const OurSolutionsCard = ({ cardData, number }) => {


  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function solutionshandler(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAV_SUB_ROUTES.SOLUTION_DETAIL, search: newSearch });
    window.location.reload();
  }

  return (
    <>
      <div
        className={cx("ourSolutionsCard", {
          reverseSolutionsLayout: number !== 0,
        })}
      >
        <img className="ourSolutionsCardImg" src={cardData.url} alt="" />
        <div className="ourSolutionCardDetails">
          <div className="headingCardSolutions">{cardData.title}</div>
          <div className="ourSolutionsDetail">{cardData.info} </div>
          <div className="offeringText">Offerings:</div>
          <div className="ourSolutionsCardLinks">
            <div className="ourSolutionsCardChildLinks">
              {cardData.offerings.map((e) => {
                return (
                  <div>
                    <img src={BulletPointer} alt="" /> &nbsp; {e}
                  </div>
                );
              })}
            </div>
          </div>
          <div onClick={() => solutionshandler(cardData.id)} title={"Explore"}>
            <IneractiveBtn title={"Explore"} btnType={"noFill"} />
          </div>
        </div>
      </div>
    </>
  );
};
