import axios from "axios";

export const getPeople = async () => {

    const url = process.env.REACT_APP_BASE_LINK + "/api/people?populate=*"
  
     return  await axios.get(url)
        .then((response) => {
          return response.data.data
      })
        .catch(error => {
          console.log(error);
        });
  }
