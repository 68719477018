import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ImpactCard } from "../components/ImpactCard";
import { NAVIGATION_ROUTES } from "../constants/routes";

import VisionIcon from "../assets/CompanyPage/visionIcon.svg";
import MissionIcon from "../assets/CompanyPage/missionIcon.svg";
import CommitmentIcon from "../assets/CompanyPage/commitmentIcon.svg";
import StoryImage from "../assets/CompanyPage/storyImage.svg";
import "../scss/common.scss";
import LinkedIcon from "../assets/CompanyPage/linked_icon.svg";
import cx from "classnames";
import debounce from "lodash/debounce";
import CsrImg from "../assets/CompanyPage/company_csr.png";
import { IneractiveBtn } from "../components/BtnInteractive";
import FounderWordQuote from "../assets/CompanyPage/founderWordQuote.svg";
import Founder from "../assets/CompanyPage/founder.svg";
import filter from "lodash/filter";
import Grad from "../assets/CompanyPage/gradBox.svg";

export const CompanyValues = () => {
  const impactArray = [
    {
      title: "Vision",
      img: VisionIcon,
      decription:
        "Lead in data transformation and elevate enterprise value by harnessing the potential of emerging technology",
    },
    {
      title: "Mission",
      img: MissionIcon,
      decription:
        "Empower businesses with AI and data-driven insights to optimize operations and stay relevant",
    },
    {
      title: "Commitment",
      img: CommitmentIcon,
      decription:
        "Collaborate as a trusted ally for data-driven decision-making and positive business impact",
    },
  ];

  return (
    <div className="valueContainer">
      <div className="valueContainerTitle">
        <span className="titleHeadContainer">Our values</span>
        <div className="valueContainerTitleDes">
          Ngenux is built on integrity, accountability, and a culture that
          fosters trust
        </div>
        <div className="valueContainerTitleDesRes">
          Your success drives Ngenux
        </div>
      </div>
      <div className="impactParentContainer">
        {impactArray.length > 0 &&
          impactArray.map((impact) => {
            return <ImpactCard impact={impact} />;
          })}
      </div>
    </div>
  );
};

export const OurStory = () => {
  return (
    <div className="ourStoryContainer">
      <div className="ourStoryLeftContainer">
        <div className="titleHeadContainer">Our story</div>
        <span className="ourStoryContainerTitleDesRes">
          The catalyst in your data journey{" "}
        </span>
        <div className="ourStoryDes">
          <p>Ngenux was founded with a vision to </p>
          <p>
            <b>Connect</b>,<b>Tie</b> and <b>Organize</b> business entities{" "}
          </p>
          <p>into meaningful hierarchies and accelerate </p>
          <p>the process with AI & Data solutions.</p>
        </div>
      </div>
      <div className="ourStoryRightContainer">
        <img src={StoryImage} alt=""></img>
      </div>
    </div>
  );
};

export const OurTeamProfile = ({ team }) => {
  return (
    <div className="profileClass">
      <img src={team.url} alt="" />

      <img className="gradCompany" src={Grad} alt="" />
      <div className="profileDetailsClass">
        <span className="profileDetailsClassTitle">{team.name}</span>
        <span className="profileDetailsClassDes">{team.designation}</span>
        <div onClick={() => window.open(team.linkedin_link)}>
          <img
            className="profileIconClass"
            src={LinkedIcon}
            alt="profile"
            width={20}
            height={20}
          />
        </div>
      </div>
    </div>
  );
};

export const OurTeam = ({ team }) => {
  const categoryTypeArray = [
    "Executive Leadership",
    "Board of Directors",
    "Featured Team Members",
  ];
  // const filteredTeam = team.filter((member) => member.categoryList && member.categoryList.length > 1);

  //  const updatedTeam = [...team, ...filteredTeam];

  const [isActiveIndex, setIsActiveIndex] = useState(0);

  const parentRef = useRef(null);
  const targetChildRef = useRef(null);
  const firstChildRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const firstContainerRef = useRef(null);
  const secondContainerRef = useRef(null);
  const thirdContainerRef = useRef(null);

  const handleClick = async (index) => {
    await setIsActiveIndex(index);
    const { scrollHeight, clientHeight } = targetChildRef.current;
    const totalScrollableDistance = scrollHeight - clientHeight;
    const eachThird = totalScrollableDistance / 3;
    targetChildRef.current.scrollTop = eachThird * index;
  };

  // const itemHeight = 200; // Height of each item in the left child
  // const totalItems = 3; // Total number of items
  // const maxTranslateY = -(itemHeight * (totalItems - 1)); // Translate value for the last item

  // const handleWheel = debounce((e) => {
  //   e.cancelable && e.preventDefault();

  //   let newY = translateY - e.deltaY;

  //   if (newY > 0) newY = 0;
  //   if (newY < maxTranslateY) newY = maxTranslateY;

  //   const index = Math.abs(Math.floor(newY / itemHeight));

  //   setIsActiveIndex(index);
  //   setTranslateY(newY);

  //   if (targetChildRef.current) {
  //     targetChildRef.current.scrollTop = -newY;
  //   }
  // }, 5); // Adjust the time as needed

  // const lockScroll = () => {
  //   document.body.style.overflow = "hidden";
  // };
  const rightDivContainerRefs = [
    firstContainerRef,
    secondContainerRef,
    thirdContainerRef,
  ];

  function handleLeftDivItemClick(index) {
    const container = rightDivContainerRefs[index].current;
    if (container) {
      const topPosition = container.offsetTop; 
      const paddingOffset = window.innerWidth <= 992 ? 150 : 200// Get the top position of the container relative to its offsetParent
      targetChildRef.current.scrollTop = topPosition - paddingOffset; // Scroll the right div to that position
    }
  }

  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // firstChildRef.current.style.transform = `translateY(-${scrollTop}px)`;
      // Total scrollable distance
      const totalScrollableDistance = scrollHeight - clientHeight;
      // Divide the total scrollable distance by 3 since we have 3 items
      // const eachThird = totalScrollableDistance / 3;
      const first = (totalScrollableDistance * 3) / 7 + 50;
      const second = (totalScrollableDistance * 5) / 7 + 100;
      // const third = totalScrollableDistance;
      let index = 0;
      if (scrollTop > first && scrollTop <= second) {
        index = 1;
      } else if (scrollTop > second) {
        index = 2;
      }
      // Calculate the index based on which third the scrollTop is currently in
      // const index = Math.floor(scrollTop / eachThird);
      if (index >= 0 && index <= 2) {
        setIsActiveIndex(index);
      }
      targetChildRef.current.scrollTop = scrollTop;
      // e.target.scrollTop = 0; // Reset scroll position of first child
    };
    const secondChild = targetChildRef.current;
    secondChild.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      secondChild.removeEventListener("scroll", handleScroll, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    // Reset scroll for child
    const resetChildScroll = () => {
      targetChildRef.current.scrollTop = 0;
    };

    // Create an intersection observer to watch the parent's visibility
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // Parent went out of view, reset child's scroll
            resetChildScroll();
          }
        });
      },
      {
        root: null, // Viewport
        threshold: 0, // Completely out of view
      }
    );

    // Start observing the parent
    observer.observe(parentRef.current);

    // Cleanup
    return () => observer.disconnect();
  }, []);

  return (
    <section ref={parentRef} className={cx("ourTeamContainer")}>
      <div className="titleHeadContainer">Our team</div>
      <span className="titleHeadDescription">
        We are committed to using AI & Data to drive excellence and shape a
        better future
      </span>

      <div className="ourTeamInfoContainer">
        <div
          className={cx("ourTeamContainerLeft", {
            transform: `translateY(-${scrollOffset}px)`,
          })}
          ref={firstChildRef}
          // onWheel={handleWheel}
          // onMouseOver={lockScroll}
          // onMouseOut={unlockScroll}
        >
          {categoryTypeArray.map((type, index) => {
            return (
              <div
                className={cx("ourTeamContainerLeftOption", {
                  ourTeamItemEnabled: isActiveIndex === index,
                  ourTeamItemDisabled: isActiveIndex !== index,
                })}
                onClick={() => {
                  handleLeftDivItemClick(index);
                }}
              >
                {type}
              </div>
            );
          })}
        </div>
        <div
          className="ourTeamContainerRight"
          id="rightContainer"
          ref={targetChildRef}
        >
          <div className="ourTeamPeopleContainer" ref={firstContainerRef}>
            {filter(team, function (o) {
              return o.category === categoryTypeArray[0];
            }).length > 0 &&
              filter(team, function (o) {
                return o.category === categoryTypeArray[0];
              }).map((teamObj) => {
                return <OurTeamProfile team={teamObj} />;
              })}
          </div>
          <div className="ourTeamPeopleContainer" ref={secondContainerRef}>
            {filter(team, function (o) {
              return o.category === categoryTypeArray[1];
            }).length > 0 &&
              filter(team, function (o) {
                return (
                  o.category === categoryTypeArray[1] ||
                  o.categoryList.includes(categoryTypeArray[1])
                );
              }).map((teamObj) => {
                return <OurTeamProfile team={teamObj} />;
              })}
          </div>
          <div className="ourTeamPeopleContainer" ref={thirdContainerRef}>
            {filter(team, function (o) {
              return o.category === categoryTypeArray[2];
            }).length > 0 &&
              filter(team, function (o) {
                return (
                  o.category === categoryTypeArray[2] ||
                  o.categoryList.includes(categoryTypeArray[2])
                );
              }).map((teamObj) => {
                return <OurTeamProfile team={teamObj} />;
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export const FounderWord = () => {
  return (
    <div className="founderContainer">
      <div className="founderContainerLeft">
        <div className="founderContainerLeftImg">
          <img src={FounderWordQuote} atl="" />
        </div>

        <div className="founderContainerLeftContent">
          <div className="founderContainerLeftContentDes">
            Ngenux customers have always appreciated the commitment and
            flexibility we bring by providing solutions to their business
            success.{" "}
          </div>
          <div className="founderContainerLeftDesignation">
            <span className="founderContainerLeftDesignationTitle">Anil N</span>
            <span className="founderContainerDesgnationInfo">
              Director & Chief Executive Officer
            </span>
          </div>
        </div>
      </div>
      <div className="founderContainerRight">
        <img src={Founder} atl="" />
      </div>
    </div>
  );
};

export const CompanyCSR = () => {
  const navigate = useNavigate(); // declared at the top level

  return (
    <div className="csrSection">
      <div className="csrSectionContainer">
      <div className="csrLeft">
        <div className="titleHeadContainer">
          Corporate Social Responsibility
        </div>
        <div className="titleHeadDescription leftDes">
          Giving back beyond measure
        </div>
        <div className="csrLeftContent">
          At Ngenux, our values extend far beyond our professional endeavors.
          Our mission is to give back to our community more than we receive. 
        </div>
        
        {window.innerWidth > 992 && <div className="leftBtnDiv">
          <div onClick={()=>navigate(NAVIGATION_ROUTES.CSR)}>
        <IneractiveBtn title={"Read More"} />
        </div>
        </div>}
      </div>
      <div className="csrRight">
        <img src={CsrImg} alt="" />
      </div>
      {window.innerWidth <= 192 && <div className="leftBtnDiv">
        <div onClick={()=>navigate(NAVIGATION_ROUTES.CSR)}>
        <IneractiveBtn title={"Read More"} />
          </div>
        </div>}
    </div>
    </div>
  );
};
