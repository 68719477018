import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  HeroBanner,
  Methodology,
  OurSolutions,
  WhatDefinesOurWork,
} from "../components/SolutionsLandingComponents";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import { getSolutions } from "../api/solutionsLandingPage";
import { solutionsSerializer } from "../utils/serializer";
import { Error } from "../components/ErrorComponent";

const SolutionsLandingPage = () => {
  const [card, setCard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorComp, setError] = useState(false);
  const getCardDetails = () => {
    getSolutions()
      .then((response) => {
        if (response) {
          setCard([...card, ...solutionsSerializer(response)]);
          setIsLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);

      });
  };

  useEffect(() => {
    getCardDetails();
  }, [getSolutions]);



  return (
    <div>
      <Helmet>
        <title>AI & Data Science Services Bengaluru | Solutions- Ngenux</title>
        <meta
          name="description"
          content="Elevate your business with Ngenux’s top-notch AI and data science services in Bengaluru by exploring our solutions of transformative insights and innovation."
        />
      </Helmet>
      {errorComp ? (
        <Error />
      ) : (
        <>
          {isLoading ? null : (
            <>
              <Header />
              <HeroBanner />
              <WhatDefinesOurWork />
              <OurSolutions cardData={card} />
              <Methodology />
              <Footer />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SolutionsLandingPage;
