import React from "react";
import { useState, useEffect } from "react";
import { SolutionsMetrices, SolutionsDetailsBanner, VisualPresentation, ChartContent, SolutionsCaseStudy } from "../components/SolutionsDetailPageComponents";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import { getSingleSolution, getStepDetails } from "../api/solutionsLandingPage";
import { solutionsSerializer, testimonialSerializer, caseStudySingleSerializer, getTestcaseStudes} from "../utils/serializer";
import { useNavigate, useLocation } from "react-router-dom";
import { Error } from "../components/ErrorComponent";
import Insights from "../components/Insights"
import { getCaseStudyCustomers } from "../api/customers";
import { Helmet } from "react-helmet";



import {
 
  getTestimonials,

} from "../api/homepage";

import "../scss/common.scss";
import { Testimonials } from "../components/HomepageComponents";

const SolutionsDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = Number(queryParams.get("id")) || 1;

  const [card, setCard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorComp, setError] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);




  const getCardDetails = (currentPage) => {
    getSingleSolution(currentPage)
      .then((response) => {
        if (response) {
          setCard([...card, ...solutionsSerializer([response])]);
          setLoading(false);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching card details:", error);
        setError(true);

      });
  };

  const CaseStudyCustomers = () => {
    getCaseStudyCustomers()
      .then((response) => {
        if (response) {
          setCaseStudies([...caseStudies, ...getTestcaseStudes(response)]);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);
      });
  };


  const getTestimonialsList = () => {
    getTestimonials()
      .then((response) => {
        if (response) {
          setTestimonials([
            ...testimonials,
            ...testimonialSerializer(response),
          ]);
        } else {
          console.error("Invalid or missing data in the response");
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error while fetching achievements:", error);
        setError(true);

      });
  };

  useEffect(() => {
    setTimeout(() => {
      CaseStudyCustomers();
    }, 1);
  }, []);


  useEffect(() => {
    setTimeout(()=>{
      getCardDetails(currentPage);

    },1)
  }, [getSingleSolution]);

  useEffect(() => {
    setTimeout(() => {
      getTestimonialsList();
     
    }, 1);
  }, []);

  let SeoTitle = card.map((e)=>{
    return e.Seo.seo_title
  })

  let SeoDescription = card.map((e)=>{
    return e.Seo.seo_description
  })
  return (
    <div>
      <Helmet>
        <title>{SeoTitle}</title>
        <meta
          name="description"
          content={SeoDescription}
        />
      </Helmet>

      {errorComp ? (
        <Error />
      ) : (
        <>
          {loading ? null : (
            <>
              <Header />
              <SolutionsDetailsBanner bannerDetails={card} />
              <SolutionsMetrices bannerDetails={card}/>
              <VisualPresentation bannerDetails={card}/>
              <ChartContent bannerDetails={card}/>
              <SolutionsCaseStudy bannerDetails={card} CaseSolutionsByTag={caseStudies}/>
              <Testimonials testimonials={testimonials} />
              <Insights/>
              <Footer />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SolutionsDetailPage;
