import React from "react";
import Header from "../components/Header";
import "../scss/privacy.scss";

import { Footer } from "../components/Footer";

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <div className="privacyContainer">
        <div className="privacyHeading">TERMS OF USE</div>
        <div className="privacyContent">
          <div>
            <strong>Acceptance of Terms</strong>
            <br />
            <br />
            <p>
              When you access this site, you acknowledge that you have read and
              agree to abide by the terms described below. If you do not agree
              to the terms discussed here, you should exit this site now.
            </p>
          </div>
          <div>
            <strong>Copyright Notice</strong>
            <br />
            <br />
            <p>
              Everything on this site is copyrighted. The copyrights are owned
              by Ngenux (“Ngenux”, implies Ngenux Solutions Private Limited. and
              all companies wholly or partially owned by it) or the original
              creator of the material. However, you are free to view, copy,
              print, and distribute Ngenux material from this site, as long as:
            </p>
            <ul>
              <li>The material is used for information only</li>
              <li>The material is used for non-commercial purposes only.</li>
              <li>Copies of any material include Ngenux copyright notice.</li>
            </ul>
          </div>
          <div>
            <br />
            <strong>Third Party Copyrights and Copyright Agent</strong>
            <br />
            <br />
            <p>
              This privacy notice does not cover the activities NGENUX
              undertakes on behalf of its clients - in which it acts as a
              service provider. Should you wish to inquire about the processing
              we undertake for any of our clients, or wish to assert your rights
              regarding that Ngenux is committed to respecting others’
              intellectual property rights, and we ask
              <br />
              our users to do the same. Ngenux may, in its sole discretion,
              terminate the accounts or access rights of users who violate
              others’ intellectual property rights. If you believe that your
              work has been copied in a way that constitutes copyright
              infringement on our Web site, please deliver the following
              information to Ngenux accessing, please contact the relevant
              client directly. NGENUX is a business that in some instances will
              utilize third party data on our client’s behalf for marketing or
              enrichment purposes. You can find privacy information related to
              these services go to Services Policy section:
            </p>
            <ul>
              <li>
                An electronic or physical signature of the person authorized to
                act on behalf of the copyright owner;
              </li>
              <li>
                A description of the copyrighted work that you claim has been
                infringed;
              </li>
              <li>
                A description of where the material that you claim is infringing
                is located on our website;
              </li>
              <li>
                Your address, telephone number, and email address so that we may
                contact you;
              </li>
              <li>
                A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law; and
              </li>
              <li>
                A statement by you, made under penalty of perjury, that the
                information in your notice to us is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner’s behalf.
              </li>
            </ul>
            <p>
              Please send this information to –Ngenux Solutions Private Limited,
              334/28 14th Cross Road 2nd Block, Jayanagar 1st Block, Jayanagar,
              Bengaluru, Karnataka 560011
            </p>
            <br />
          </div>
          <div>
            <strong>Trademarks</strong>
            <br />
            <br />
            <p>
              Ngenux Logo is a trademark of Ngenux Solutions Private Limited.
              You may not use these without the written permission of Ngenux.
            </p>
            <br />
          </div>
          <div>
            <strong>No Warranty</strong>
            <br />
            <br />
            <p>
              While Ngenux attempts to provide accurate information on this Web
              site, it assumes no responsibility for accuracy. Ngenux may change
              the information on the site at any time without notice.
              <br />
              Material on this site is provided “as is” and does not constitute
              a warranty of any kind, either expressed or implied. Ngenux
              disclaims all warranties, expressed or implied. This includes but
              is not limited to implied warranties of merchantability, fitness
              for a particular purpose, and non-infringement. It also includes
              any expressed or implied warranties arising from any course of
              dealing, usage, or trade practice.
            </p>
            <br />
          </div>
          <div>
            <strong>Limitation of liability</strong>
            <br />
            <br />
            <p>
              Ngenux is not liable for any direct, indirect, special,
              incidental, or consequential damages arising out of the use-or the
              inability to use-the material on this site. This includes but is
              not limited to the loss of data or loss of profit, even if Ngenux
              was advised of the possibility of such damages.
            </p>
            <br />
            <div>
              <strong>Links</strong>
              <br />
              <br />
              <p>
                While this Web site may contain links to third-party sites,
                Ngenux is not responsible for the content of any linked sites.
              </p>
              <br />
            </div>
            <div>
              <strong>Your Posted Information</strong>
              <br />
              <br />
              <p>
                You are prohibited from posting or transmitting any unlawful,
                threatening, obscene, libelous, or otherwise offensive material.
              </p>
              <br />
            </div>
            <div>
              <strong>Your Submission</strong>
              <br />
              <br />
              <p>
                Any material, information, or idea submitted or posted on this
                Web site will be considered non-confidential and
                non-proprietary. Ngenux may share or otherwise use your
                submission for any purpose whatsoever. If any of the information
                submitted constitutes personal data, you agree that Ngenux may
                transmit such personal data across international boundaries for
                any Ngenux business purpose. Personal data will be treated as
                set forth in our Privacy Statement. Jurisdiction and Choice of
                Law All claims or issues regarding this Web site, shall be
                governed according to the laws of the State of Karnataka, India.
                Any legal action concerning these Terms or this Web site must be
                brought within one (1) year after the claim or cause of action
                arises and must be brought in the State of Karnataka.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
